import { Reducer } from "redux"
import { actionTypes, tagTypes, layoutTypes, statusTypes } from "../actions/actionTypes"

export interface ILang {
  en: string;
  nl: string;
}

export interface ITag {
  type: tagTypes;
  value: number;
  title: ILang;
}

export interface ICrumb {
  title: string | ILang;
  link: string;
  bg: string;
}

export interface IMenu {
  title_nl: string;
  title_en: string;
  stub: string;
}

export interface IMenuId {
  [id: number]: IMenu;
}

export interface IHomepage {
  layout: number;
  text1_nl: string;
  text1_en: string;
  link1: string;
  text2_nl: string;
  text2_en: string;
  link2: string;
  link3: string;
  link4: string;
  text_colour: string;
  image_id: number;
  tour_colour: number;
}

export interface IContact {
  content_en: string;
  content_nl: string;
}

export interface ICountry {
  code: string;
  nl: string;
  en: string;
}

export interface ICountryId {
  [code: string]: ICountry;
}

export interface IDataset {
  status: statusTypes;
  countries: ICountryId;
  splashpage: {
    image: string;
    layout: layoutTypes;
    colour: string;
    content: string;
    subcontent: string;
  },
  homepage: IHomepage,
  contact: IContact,
  menus: IMenu[][];
}


export interface IAnaf {
  width: number;
  height: number;
  lang: string;
  tags: ITag[];
  crumbs: ICrumb[];
  lightboxIsOpen: boolean;
  lightboxIndex: number;
  lightboxSIndex: number;
  searchOpen: boolean;
  topsearchOpen: boolean;
  cloudsearchOpen: boolean;
  dataset: IDataset;
  contactSubmit: boolean;
}

const initState: IAnaf = {
  width: 1000,
  height: 1000,
  lang: 'EN',
  tags: [],
  crumbs: [],
  lightboxIsOpen: false,
  lightboxIndex: 0,
  lightboxSIndex: 0,
  searchOpen: false,
  topsearchOpen: false,
  cloudsearchOpen: false,
  dataset: {
    status: statusTypes.INIT,
    countries: {},
    splashpage: {
      image: '',
      layout: layoutTypes.NONE,
      colour: '#fff',
      content: '',
      subcontent: '',
    },
    homepage: {
      layout: 0,
      text1_nl: '',
      text1_en: '',
      link1: '',
      text2_nl: '',
      text2_en: '',
      link2: '',
      link3: '',
      link4: '',
      text_colour: '',
      image_id: 0,
      tour_colour: 0,
    },
    contact: {
      content_en: '',
      content_nl: '',
    },
    menus: [],
  },
  contactSubmit: false,
}

export const anaf: Reducer<IAnaf> = (state = initState, action) => {
  switch(action.type) {
    case actionTypes.ANAF_UPDATEDIMENSIONS: {
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      }
    }
    break;

    case actionTypes.ANAF_UPDATE_LANG: {
      return {
        ...state,
        lang: action.payload.lang,
      }
    }
    break;

    case actionTypes.ANAF_ADD_TAG: {
      let tag: ITag = {
        type: action.payload.type,
        value: action.payload.value,
        title: action.payload.title,
      }

      return {
        ...state,
        tags: [...state.tags, tag],
      }
    }
    break;

    case actionTypes.ANAF_REMOVE_TAG: {
      let newtags = new Array();
      let type = action.payload.type;
      let value = action.payload.value;

      state.tags.forEach((tag) => {
        if(tag.type == type && tag.value == value)
        {}
        else {
          newtags.push(tag);
        }
      })
      return {
        ...state,
        tags: newtags,
      }
    }
    break;

    case actionTypes.ANAF_ADD_CRUMBS: {
      let crumbs: ICrumb[] = action.payload.crumbs

      return {
        ...state,
        crumbs: [...state.crumbs, ...crumbs],
      }
    }
    break;

    case actionTypes.ANAF_REMOVE_ALL_TAGS: {
      return {
        ...state,
        tags: [],
      }
    }

    case actionTypes.ANAF_RESET_CRUMBS: {
      return {
        ...state,
        crumbs: [],
      }
    }
    break;

    case actionTypes.ANAF_LIGHTBOX_OPEN: {
      return {
        ...state,
        lightboxIsOpen: true,
        lightboxIndex: action.payload.index,
        lightboxSIndex: action.payload.sindex,
      }
    }
    break;

    case actionTypes.ANAF_SEARCH_TOGGLE: {
      return {
        ...state,
        searchOpen: !state.searchOpen,
      }
    }
    break;

    case actionTypes.ANAF_TOP_SEARCH: {
      return {
        ...state,
        topsearchOpen: action.payload.val,
      }
    }
    break;

    case actionTypes.ANAF_CLOUD_SEARCH: {
      return {
        ...state,
        cloudsearchOpen: action.payload.val,
      }
    }
    break;

    case actionTypes.ANAF_LIGHTBOX_CLOSE: {
      return {
        ...state,
        lightboxIsOpen: false,
      }
    }
    break;

    case actionTypes.ANAF_LIGHBOX_SET_INDEX: {
      return {
        ...state,
        lightboxIndex: action.payload.index,
      }
    }
    break;

    case actionTypes.ANAF_INIT_ANAF: {
      let data = action.payload.data
      data.status = statusTypes.LOADED

      return {
        ...state,
        dataset: data,
        lang: action.payload.lang,
      }
    }
    break;


    case actionTypes.ANAF_CONTACT_SUCCESS: {
      return {
        ...state,
        contactSubmit: true
      }
    }
    break;


  }
  return state
}
