import * as React from "react";
import {createRef} from "react";

import {connect} from "react-redux"
import {IData} from "../../reducers/dataReducer"
import {IAnaf} from "../../reducers/anafReducer"

import {closeLightbox} from "../../actions/anafActions"

import {SVGCross, SVGArrowRight, SVGArrowLeft} from '../../tools/svgTools'
import zoomin from '../../images/AAF_SVG_incons-3_AAF Zoom +.svg'
import zoomout from '../../images/AAF_SVG_incons-3_AAF Zoom -.svg'


import styles from './styles.module.css'

interface State {
  scale: number;
  x: number;
  y: number;
  startX: number;
  startY: number;
  offsetX: number;
  offsetY: number;
  mouseDown: boolean;
  sindex: number;
}

interface parentProps {
  artwork_ids: number[];
}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  closeLightbox: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
    anaf: state.anaf,
  }
};

const mapDispatchToProps = dispatch => ({
  closeLightbox: () => {dispatch( closeLightbox())},
});


type Props = StoreProps & DispatchProps & parentProps

export class Lightbox extends React.Component<Props, State> {
  imgRef: any;
  artwork: any;
  artist: any;

  constructor(props) {
    super(props);

    this.state = {
      scale: 1,
      x: 0,
      y: 0,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
      mouseDown: false,
      sindex: this.props.anaf.lightboxSIndex,
    }

    this.imgRef = React.createRef()
    this.handleKeyDown = this.handleKeyDown.bind(this)

  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyDown);
  }


  render() {
    let index = this.props.anaf.lightboxIndex
    let id = this.props.artwork_ids[index]
    this.artwork = this.props.data.artworks.byIds[id]
    this.artist = this.props.data.artists.byIds[this.artwork.artist_id]

    return  <div className={styles.lightbox}>
      <div className={styles.outer}>
        <div className={`d-flex flex-column ${styles.frame}`}>
          <div className={`d-flex align-items-center justify-content-center ${styles.imageHolder}`}>
            <img
              className={styles.imgg}
              src={this.image()}
              style={this.scaling()}
              onMouseDown={(e) => this.handleMouseDown(e)}
              onMouseUp={() => this.handleMouseUp()}
              onMouseMove={(e) => this.handleMouseMove(e)}
              draggable={false}
              onKeyDown={()=> console.log("xx")}
              />
             </div>
          {this.footer()}
        </div>
        <div className={styles.buttonHolder}>
          <div className="link" onClick={() => this.close()}>
            {SVGCross('#fff')}
          </div>
          <div className={`link ${styles.zoom}`} onClick={() => this.zoomin()}>
            <img src={zoomin}/>
          </div>
          <div className={`link ${styles.zoom}`} onClick={() => this.zoomout()}>
            <img src={zoomout} />
          </div>
        </div>
      </div>
    </div>
  }

  image() {
    let a = this.artwork
    if (!a.part_of_set)
      return this.artwork.image
    let id = this.artwork.subset[this.state.sindex]
    let artwork = this.props.data.artworks.byIds[id]
    return artwork.image
  }

  footer() {
    return <div className={`bold mt-auto ${styles.text}`}>
      {this.title()  }
      {this.artwork.part_of_set? this.setnav() : <span></span>}
    </div>
  }

  title() {
    let title = ''

    if (this.artwork.part_of_set){
      let id = this.artwork.subset[this.state.sindex]
      let artwork = this.props.data.artworks.byIds[id]
      title = artwork.title
    }
    else
      title = this.artwork.title

    let fulltitle = `${this.artist.name} | ${title} | ${this.artwork.year}`
    return fulltitle
  }

  setnav(){
    let c = this.state.sindex
    let size = this.artwork.subset.length

    const arrowStyle = {
      strokeWidth: 3,
      width: 18,
      marginBottom: 8,
      paddingTop: 5,
    }

    const inline = {
      display: 'inline-block'
    }

    return <div className={styles.navholder} >
      <div style={inline} className="link" onClick={() => this.prevSub()}>{SVGArrowLeft('#fff', arrowStyle)}</div>
      <div className={`bold ${styles.subnav}`}>{c + 1} / {size}</div>
      <div style={inline} className="link" onClick={() => this.nextSub()}>{SVGArrowRight('#fff', arrowStyle)}</div>
      <div>{this.preload()}</div>
    </div>
  }

  preload(){
    return <div style={{display: 'none'}}>
    {this.artwork.subset.map(id =>
      <img key={id} src={this.props.data.artworks.byIds[id].image}/>
    )}
    </div>
  }

  handleKeyDown(e) {
    if(e.code == 'Escape') {
      this.close()
    }
  }

  handleMouseDown(e) {
    this.setState({
      mouseDown: true,
      startX: e.clientX,
      startY: e.clientY,
      offsetX: this.state.x,
      offsetY: this.state.y,
    })
  }

  handleMouseUp() {
    this.setState({
      mouseDown: false,
    })
  }

  handleMouseMove(e) {
    if (this.state.mouseDown)
      this.handleMove(e.clientX, e.clientY)
  }

  handleMove(x, y) {
    if (this.state.scale == 1)
      return

    let dx = x - this.state.startX + this.state.offsetX
    let dy = y - this.state.startY + this.state.offsetY

    this.setState({
      x: dx,
      y: dy,
    })
  }

  scaling() {
    const s = this.state
    return {
      transform: `translate3d(${s.x}px, ${s.y}px, 0px) scale3d(${s.scale}, ${s.scale} , 1)`,
      cursor: (this.state.scale != 1)? 'move' : '',
    }
  }

  close() {
    this.props.closeLightbox()
  }

  zoomin() {
    let ns = Math.min(this.state.scale * 1.5 , 5)
    this.setState({
      scale: ns,
    })
  }

  zoomout() {
    let ns = Math.max(this.state.scale / 1.5, 1)
    this.setState({
      scale: ns,
      x: (ns == 1)? 0 : this.state.x,
      y: (ns == 1)? 0 : this.state.y

    })
  }

  nextSub() {
    let next = (this.state.sindex + 1) % this.artwork.subset.length
    this.setState({
      sindex: next,
    })
  }

  prevSub() {
    let prev = (this.artwork.subset.length + this.state.sindex - 1) % this.artwork.subset.length
    this.setState({
      sindex: prev,
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lightbox);
