import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import axios, { AxiosRequestConfig, AxiosPromise } from "axios";

import { Button } from "../buttons/Button";
import { translate } from "../../tools/translateTools";
import { A_ID_Sort } from "../../tools/generalTools";
import { present } from "./form_tools";

import { IAnaf, ICrumb, ILang } from "../../reducers/anafReducer";
import { resetCrumbs, addCrumbs } from "../../actions/anafActions";
import { Input } from "reactstrap";

import classNames from "classnames";

import styles from "./order.module.scss";

interface State {
  order: {
    name: string;
    address: string;
    zipcode: string;
    country: string;
    email: string;
    phone: string;
    amount: number;
    remark: string;
    lang: string;
    city: string;
  };
  show_warning: boolean;
  sending: boolean;
  path: string;
}

interface parentProps {
  history: any;
  location: any;
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetCrumbs: () => {
    dispatch(resetCrumbs());
  },
  addCrumbs: (crumb: ICrumb[]) => {
    dispatch(addCrumbs(crumb));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class Order extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      order: {
        name: "",
        address: "",
        zipcode: "",
        country: "NONE",
        email: "",
        phone: "",
        amount: 0,
        remark: "",
        lang: "",
        city: "",
      },
      show_warning: false,
      sending: false,
      path: "",
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.location.pathname != state.path) {
      const title = "TRUE COLORS";
      const ordertitle_nl = translate("NL", "form_title");
      const ordertitle_en = translate("EN", "form_title");

      props.resetCrumbs();
      let home: ICrumb = { title: "home", link: "/home", bg: "#fff" };
      let contact: ICrumb = {
        title: { en: title, nl: title },
        link: "/page/true-colors",
        bg: "#fff",
      };
      let order: ICrumb = {
        title: { en: ordertitle_en, nl: ordertitle_nl },
        link: "/order",
        bg: "#fff",
      };
      props.addCrumbs([home, contact, order]);
      return {
        path: props.location.pathname,
      };
    }

    return null;
  }

  render() {
    return (
      <div className={classNames(styles.orderwindow, "row", "mr-0", "rl-0")}>
        <Helmet>
          <title>Order - AkzoNobel Art Foundation</title>
          <meta
            name="description"
            content={`Order - AkzoNobel Art Foundation`}
          />
        </Helmet>
        <div className={classNames("col-12", "col-md-6", styles.orderform)}>
          {this.form()}
        </div>
        <div
          className={classNames(
            "col-12",
            "col-md-6",
            styles.subwindow,
            styles.infowindow
          )}
        >
          {this.info()}
        </div>
      </div>
    );
  }

  info() {
    const lang = this.props.anaf.lang;

    let note1_en =
      "Please note: after completing the order form, you will receive an email with a request to pay your order via bank transfer.";
    let note2_en =
      "We strive to ship orders within one week upon receiving payment.";
    let note3_en = "For orders exceeding 5 copies, please contact us using our";
    let link_en = "contactform";

    let note1_nl =
      "Let op: na het invullen van het bestelformulier ontvangt u een e-mail met het verzoek uw bestelling te betalen via bankoverschrijving.";
    let note2nl =
      "Wij doen ons best om uw bestelling binnen één week na ontvangst van betaling te versturen.";
    let note3_nl =
      "Voor bestellingen van meer dan 5 magazines, kunt u contact opnemen via ons";
    let link_nl = "contactformulier";

    return (
      <div>
        <div className={styles.image}>
          <img src="/cover2.jpg" className="img-fluid" />
        </div>
        <div className={styles.notes}>
          <p>
            {lang == "EN" ? note1_en : note1_nl}{" "}
            {lang == "EN" ? note2_en : note2nl}
          </p>
          <p>
            {lang == "EN" ? note3_en : note3_nl}&nbsp;
            <Link to="/contact">{lang == "EN" ? link_en : link_nl}</Link>
          </p>
        </div>
      </div>
    );
  }

  form() {
    const o = this.state.order;
    const lang = this.props.anaf.lang;
    return (
      <div>
        <div className={styles.subwindow}>
          <div className="bold">
            <h1 className={styles.header}>
              {translate(this.props.anaf.lang, "form_title")}
            </h1>
          </div>
        </div>

        <div className={styles.formwindow}>
          <Input
            placeholder={translate(this.props.anaf.lang, "form_name")}
            invalid={o.name.trim().length == 0 && this.state.show_warning}
            type="text"
            name="name"
            value={o.name}
            className={styles.input}
            onChange={(e) => this.handleInputChange(e)}
          />
          <Input
            placeholder={translate(this.props.anaf.lang, "form_address")}
            className={styles.input}
            invalid={o.address.trim().length == 0 && this.state.show_warning}
            type="text"
            name="address"
            value={o.address}
            onChange={(e) => this.handleInputChange(e)}
          />
          <Input
            placeholder={translate(this.props.anaf.lang, "form_zipcode")}
            className={styles.input}
            invalid={o.zipcode.trim().length == 0 && this.state.show_warning}
            type="text"
            name="zipcode"
            value={o.zipcode}
            onChange={(e) => this.handleInputChange(e)}
          />
          <Input
            placeholder={translate(this.props.anaf.lang, "form_city")}
            className={styles.input}
            invalid={o.city.trim().length == 0 && this.state.show_warning}
            type="text"
            name="city"
            value={o.city}
            onChange={(e) => this.handleInputChange(e)}
          />

          <Input
            className={classNames(styles.input, styles.select)}
            type="select"
            invalid={o.country === "NONE" && this.state.show_warning}
            name="country"
            value={o.country}
            onChange={(e) => this.handleInputChange(e)}
            children={[
              <option key="NONE" value="NONE">
                {translate(lang, "form_country")}
              </option>,
              A_ID_Sort(
                this.props.anaf.dataset.countries,
                lang.toLowerCase()
              ).map((c) => (
                <option key={c.code} value={c.code}>
                  {c[lang.toLowerCase()]}
                </option>
              )),
            ]}
          />

          <Input
            className={styles.input}
            placeholder={translate(this.props.anaf.lang, "form_email")}
            invalid={o.email.trim().length == 0 && this.state.show_warning}
            type="text"
            name="email"
            value={o.email}
            onChange={(e) => this.handleInputChange(e)}
          />

          <Input
            className={classNames(styles.input, styles.select)}
            invalid={o.amount == 0 && this.state.show_warning}
            type="select"
            name="amount"
            value={o.amount}
            onChange={(e) => this.handleInputChange(e)}
            children={[
              <option key="select" value={0}>
                {translate(lang, "form_amount")}
              </option>,
              <option key="amount1" value={1}>
                {this.itemstring(1, lang)}
              </option>,
              <option key="amount2" value={2}>
                {this.itemstring(2, lang)}
              </option>,
              <option key="amount3" value={3}>
                {this.itemstring(3, lang)}
              </option>,
              <option key="amount4" value={4}>
                {this.itemstring(4, lang)}
              </option>,
              <option key="amount5" value={5}>
                {this.itemstring(5, lang)}
              </option>,
            ]}
          />
          <div className={styles.formlabel}>
            {translate(lang, "form_remark")}
          </div>
          <Input
            className={styles.textarea}
            type="textarea"
            name="remark"
            value={o.remark}
            onChange={(e) => this.handleInputChange(e)}
          />

          {this.state.show_warning ? this.warning() : ""}

          {this.state.sending ? (
            this.sending()
          ) : (
            <div className="link" onClick={() => this.submit()}>
              <Button
                style={{ float: "right", backgroundColor: "#000" }}
                arrow="right"
                text={translate(this.props.anaf.lang, "send")}
                color="#fff"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  sending() {
    const str = this.props.anaf.lang == "EN" ? "Sending..." : "Versturen...";
    return <div>{str}</div>;
  }

  itemstring(amount, lang) {
    const price = amount * 25;
    if (lang == "NL") return `${amount} stuks (€ ${price},-)`;

    if (amount > 1) return `${amount} items (€ ${price},-)`;
    return `${amount} item (€ ${price},-)`;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newstate = {
      ...this.state.order,
      [name]: value,
    };

    this.setState({
      order: {
        ...newstate,
      },
    });
  }

  warning() {
    return (
      <div className={classNames(styles.warning, "bold")}>
        {translate(this.props.anaf.lang, "form_warning")}
      </div>
    );
  }

  submit() {
    if (!this.validate()) {
      this.setState({
        show_warning: true,
      });
      return;
    }

    this.setState({
      sending: true,
    });

    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    let that = this;

    let order = this.state.order;
    order.lang = this.props.anaf.lang;

    axios
      .post(
        "/order",
        {
          order: this.state.order,
        },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then(function(result) {
        console.log("worked");
        that.props.history.push("/page/thanks");
      })
      .catch(function(error) {
        if (error.response) {
          console.log("Error", error.message);
        }
      });
  }

  validate() {
    const o = this.state.order;
    return (
      present(o.name) &&
      present(o.address) &&
      present(o.zipcode) &&
      present(o.city) &&
      present(o.email) &&
      o.amount > 0 &&
      o.country != "NONE"
    );
  }

  exists(val) {
    return;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
