import { actionTypes, tagTypes } from "./actionTypes"
import {IDataset, ICrumb, ILang} from "../reducers/anafReducer"
import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';

export function updateDimensions(w: number, h: number) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_UPDATEDIMENSIONS, payload: {width: w, height: h}})
  }
}

export function updateLang(lang: string) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_UPDATE_LANG, payload: {lang: lang}})
  }
}

export function addTag(type: tagTypes, value: number, title: ILang) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_ADD_TAG, payload: {type: type, value: value, title: title}})
  }
}

export function removeTag(type: tagTypes, value: number) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_REMOVE_TAG, payload: {type: type, value: value}})
  }
}

export function removeAllTags() {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_REMOVE_ALL_TAGS})
  }
}

export function resetCrumbs() {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_RESET_CRUMBS, payload: {}})
  }
}

export function addCrumbs(crumbs: ICrumb[]) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_ADD_CRUMBS, payload: {crumbs: crumbs}})
  }
}

export function openLightbox(index: number, sindex: number = 0) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_LIGHTBOX_OPEN, payload: {index: index, sindex: sindex}})
  }
}

export function closeLightbox() {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_LIGHTBOX_CLOSE, payload: {}})
  }
}

export function setIndexLightbox(index: number) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_LIGHBOX_SET_INDEX, payload: {index: index}})
  }
}

export function toggleSearch() {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_SEARCH_TOGGLE})
  }
}

export function toggleTopSearch(val: boolean) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_TOP_SEARCH, payload: {val: val}})
  }
}

export function setCloudSearch(val: boolean) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_CLOUD_SEARCH, payload: {val: val}})
  }
}

export function initAnaf(data: IDataset, lang: string) {
  return function(dispatch) {
    dispatch({type: actionTypes.ANAF_INIT_ANAF, payload: {data: data, lang: lang}})
  }
}

export function submitContact(name: string, email: string, msg: string) {
  return (dispatch, getState) => {

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    axios.post("/contact/submit",{
      message: {
        name: name,
        email: email,
        msg: msg,
      }
    }, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
    .then(function(result) {
      console.log(result)
      dispatch({type: actionTypes.ANAF_CONTACT_SUCCESS})
    }).catch(function (error) {
      if (error.response) {
        console.log('Error', error.message);
      }
    })
  }
}
