import * as React from "react";
import {connect} from 'react-redux';
import {IData} from "../../reducers/dataReducer"
import { withRouter, Link } from 'react-router-dom'

import * as CONST from "../exhibition/consts"

import {IAnaf, ITag, ILang} from "../../reducers/anafReducer"
import {removeAllTags, addTag} from "../../actions/anafActions"

import classNames from "classnames"

import {tagTypes} from "../../actions/actionTypes"
import {translate} from "../../tools/translateTools"
import {stripMarks} from '../pages/ContentPage'
import {techniqueList} from "../../tools/tagTools"
import {colourTags} from "../../tools/colorTools"

import {ResultTag} from "../tags/ResultTag"
import {Button} from "../buttons/Button"

import {SVGCross, SVGPlus, SVGArrowDown} from "../../tools/svgTools"
import {ArtistURL, ArtworkURL} from '../../tools/pageTools'
import styles from './search.module.scss'

import {latinize} from '../../tools/translateTools'

enum searchTypes {
  NAME,
  NAMES,
  BIRTHCOUNTRY,
  BIRTHYEAR,
  BIRTHPLACE,
  TITLE,
  YEAR,
  TECHNIQUE,
  COLOR,
  THEME,
  EXHIBITION,
  HIGHLIGHT,
}

interface State {
  value: string;
  suggestions: any;
  searchtype: searchTypes;
}

interface parentProps {
  history: any;
  advanced: boolean;
}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  addTag: Function;
  removeAllTags: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf,
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  addTag: (type: tagTypes, value: number, title: ILang) => {dispatch( addTag(type, value, title) )},
  removeAllTags: () => {dispatch( removeAllTags())},
});

type Props = StoreProps & DispatchProps & parentProps

const SOURCE_AUTHOR = 0;
const SOURCE_ARTWORK = 1;
const SOURCE_TECHNIQUE = 2;
const SOURCE_COLOR = 3;
const SOURCE_THEME = 4;
const SOURCE_EXHIBITION = 5;
const SOURCE_YEAR = 6;

export class Searchbox extends React.Component<Props, State> {
  searchinput: any[];
  themes: any[];
  highlights: any[];
  exhibitions: any[];
  refInput: any;
  years: any[];

  constructor(props) {
    super(props);

    this.refInput = React.createRef();

    this.searchinput = new Array();
    // #TODO filter published
    this.searchinput.push({ title: SOURCE_AUTHOR, searchinput: this.artistlist() })
    this.searchinput.push({ title: SOURCE_ARTWORK, searchinput: this.artworklist() })

    this.populateExhibitionlist();
    this.populateYears();

    this.state = {
      value: '',
      suggestions: [],
      searchtype: this.props.advanced? searchTypes.NAME: searchTypes.NAMES,
    };
  }

  artistlist() {
    techniqueList
    return Object.keys(this.props.data.artists.byIds).map((id) => {
      let artist = this.props.data.artists.byIds[id]
      return {
        source: SOURCE_AUTHOR,
        id: artist.id,
        name: artist.name,
        published: artist.published,
        birth_year: artist.birth_year,
        birth_place: artist.birth_place,
        birth_country: {en: artist.country_en, nl: artist.country_nl}
      }
    })
  }

  artworklist() {
    return Object.keys(this.props.data.artworks.byIds).map((id) => {
      let artwork = this.props.data.artworks.byIds[id]
      return {
        source: SOURCE_ARTWORK,
        id: artwork.id,
        name: artwork.title,
        published: artwork.published,
        year: artwork.year,
        colors: artwork.ctags,
      }
    })
  }

  populateExhibitionlist() {
    this.themes = new Array();
    this.highlights = new Array();
    this.exhibitions = new Array();

    Object.keys(this.props.data.exhibitions.byIds).forEach((id) => {
      let eb = this.props.data.exhibitions.byIds[id]
      let data = {id: parseInt(id), title: {en: eb.title_en, nl: eb.title_nl}}
      switch(eb.etype){
        case CONST.EXHIBITION:
          data["source"] = SOURCE_EXHIBITION
          this.exhibitions.push(data);
          break;
        case CONST.THEME:
          data["source"] = SOURCE_THEME
          this.themes.push(data);
          break;
        case CONST.HIGHLIGHT:
          data["source"] = SOURCE_EXHIBITION
          this.highlights.push(data);
          break;
      }
    })
  }

  populateYears() {
    this.years = new Array();

    this.props.data.artworks.years.forEach((year) => {
      if(year)
        this.years.push({id: year, title: {en: "" + year, nl: "" + year}, source: SOURCE_YEAR})
    })
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp(latinize(escapedValue), 'i');

    switch(this.state.searchtype){
      case searchTypes.NAMES: return this.findByNames(regex)
      case searchTypes.NAME: return this.findByArtist(regex, searchTypes.NAME)
      case searchTypes.BIRTHCOUNTRY: return this.findByArtist(regex, searchTypes.BIRTHCOUNTRY)
      case searchTypes.BIRTHYEAR: return this.findByArtist(regex, searchTypes.BIRTHYEAR)
      case searchTypes.BIRTHPLACE: return this.findByArtist(regex, searchTypes.BIRTHPLACE)
      case searchTypes.TITLE: return this.findByArtwork(regex, searchTypes.TITLE)
    }

    return []
  }

  findByArtwork(regex, field) {
    const lang = this.props.anaf.lang
    return this.searchinput
      .map(section => {
        if (section.title == SOURCE_ARTWORK) {
            return {
              title: section.title,
              searchinput: section.searchinput.filter(item => {
                if (item.published) {
                  switch(this.state.searchtype){
                    case searchTypes.TITLE: return regex.test(latinize(item.name))
                    // case searchTypes.YEAR: return regex.test(latinize(item.year))
                  }
                }})
            }
          }
        return {
          title: section.title,
          searchinput: [],
        };
      }).filter(section => section.searchinput.length > 0);
  }

  findByArtist(regex, field) {
    return this.searchinput
      .map(section => {
        if (section.title == SOURCE_AUTHOR) {
            return {
            title: section.title,
            searchinput: section.searchinput.filter(item => {
                if (item.published) {
                  switch(this.state.searchtype){
                    case searchTypes.NAME: return regex.test(latinize(item.name))
                    case searchTypes.BIRTHYEAR: return regex.test(latinize(item.birth_year))
                    case searchTypes.BIRTHPLACE: return regex.test(latinize(item.birth_place))
                    case searchTypes.BIRTHCOUNTRY:
                      let c = (this.props.anaf.lang == "EN")? item.birth_country["en"] : item.birth_country["nl"]
                      return regex.test(latinize(c))
                  }
                }})
            }
          }
        return {
          title: section.title,
          searchinput: [],
        };
      }).filter(section => section.searchinput.length > 0);
  }

  findByNames(regex) {
    return this.searchinput
      .map(section => {
        return {
          title: section.title,
          searchinput: section.searchinput.filter(item => {
            return item.published && regex.test(latinize(item.name))})
        };
      }).filter(section => section.searchinput.length > 0);
  }

  render() {
    let tit = translate(this.props.anaf.lang, 'advanced_search')
    //
    return <div>
      {this.advanced()}
      <div className={classNames(styles.advancedToggle)}>
        <div onClick={() => this.toggleAdvanced()} className={classNames(styles.toggle, "link")}>
        <Button
          arrow={this.isAdvanced()? 'cross' : 'plus'}
          front={true}
          color="#fff"
          text={tit}
          extraclass={styles.advancedToggleButton}
        />
        </div>
      </div>
      {(this.showInput())? this.inputfield() : this.resultcloud()}
    </div>
  }

  showInput():boolean {
    switch(this.state.searchtype){
      case searchTypes.NAMES:
      case searchTypes.NAME:
      case searchTypes.BIRTHYEAR:
      case searchTypes.BIRTHPLACE:
      case searchTypes.BIRTHCOUNTRY:
      case searchTypes.TITLE:
        return true
    }

    return false
  }

  inputfield() {
    return <div>
      <div className={styles.inputholder}>
      <input
        className={styles.searchbox}
        autoFocus={true}
        placeholder={this.placeholder()}
        onChange={(e) => this.onChangeValue(e.target.value)}
        onKeyPress={(e) => this.detectKey(e.charCode)}
        ref={this.refInput}
        value={this.state.value}
      />
      </div>
      {this.results()}
    </div>
  }

  resultcloud() {
    let results = []
    switch(this.state.searchtype) {
      case searchTypes.TECHNIQUE:
        results = techniqueList().map((t, id) => {
          return {id: id, title: t, source: SOURCE_TECHNIQUE}
        })
        break;
      case searchTypes.COLOR:
        results = colourTags().map((t, id) => {
          return {id: id, title: t, source: SOURCE_COLOR}
        })
        break;
      case searchTypes.THEME: results = this.themes
        break;
      case searchTypes.EXHIBITION: results = this.exhibitions
        break;
      case searchTypes.HIGHLIGHT: results = this.highlights
        break;
      case searchTypes.YEAR: results = this.years
        break;

    }

    return <div className={styles.resultsHolder}>
    {results.map((res) => {
      const {id, title, source} = res
      let tit = stripMarks((this.props.anaf.lang == "EN")? title["en"] : title["nl"])
      return <ResultTag
             key={`${source}${id}`}
             arrow="right"
             text={tit}
             onClick={() => this.onSelected(source, id, title )}
           />
      })}
    </div>
  }

  toggleAdvanced() {
    this.setState({
      searchtype: this.isAdvanced()? searchTypes.NAMES : searchTypes.NAME,
      value: '',
    })
  }

  isAdvanced() {
    return this.state.searchtype != searchTypes.NAMES
  }

  placeholder() {
    const lang = this.props.anaf.lang
    switch(this.state.searchtype){
      case searchTypes.NAME: return translate(lang, 'name')
      case searchTypes.BIRTHCOUNTRY: return translate(lang, 'country')
      case searchTypes.BIRTHYEAR: return translate(lang, 'birth_year')
      case searchTypes.BIRTHPLACE: return translate(lang, 'birth_place')
      case searchTypes.TITLE: return translate(lang, 'title')
      // case searchTypes.YEAR: return translate(lang, 'year')
    }
    return (this.props.anaf.lang == "EN")? 'Artist or Artwork' : 'Kunstenaar of Kunstwerk'
  }


//{this.tag('nationality', searchTypes.NATIONALITY)}

  advanced() {
    return <div className={classNames(styles.advancedHolder, {[`${styles.openAdvanced}`]: this.isAdvanced()})}>
      <div className={classNames(styles.advancedHeader, "row", "no-gutters")}>
        <div className="col-12 col-md-4">
          <h3 className={classNames(styles.headerTitle, "bold")}>{translate(this.props.anaf.lang, 'artist')}</h3>
          {this.tag('name', searchTypes.NAME)}
          {this.tag('birth_year', searchTypes.BIRTHYEAR)}
          {this.tag('birth_place', searchTypes.BIRTHPLACE)}
          {this.tag('country', searchTypes.BIRTHCOUNTRY)}
        </div>
        <div className="col-12 col-md-4">
          <h3 className={classNames(styles.headerTitle, "bold")}>{translate(this.props.anaf.lang, 'artwork')}</h3>
          {this.tag('title', searchTypes.TITLE)}
          {this.tag('year', searchTypes.YEAR)}
          {this.tag('technique', searchTypes.TECHNIQUE)}
          {this.tag('color', searchTypes.COLOR)}
          {this.tag('theme', searchTypes.THEME)}
        </div>
        <div className="col-12 col-md-4">
          <h3 className={classNames(styles.headerTitle, "bold")}>{translate(this.props.anaf.lang, 'set')}</h3>
          {this.tag('exhibition', searchTypes.EXHIBITION)}
          {this.tag('highlight', searchTypes.HIGHLIGHT)}
        </div>
      </div>
    </div>
  }

  tag(string_key:string, type) {
    return <div onClick={() => this.setSearchType(type)}>
      <div className={classNames(styles.tag, "bold link")}>
        {(this.state.searchtype == type)? SVGCross('#333') : SVGPlus('#333')}
        {translate(this.props.anaf.lang, string_key)}
      </div>
    </div>
  }

  setSearchType(type) {
    this.setState({
      searchtype: type,
      value: '',
    })

    if(this.showInput())
      this.refInput.current.focus()
  }

  results() {
    let res = this.getSuggestions(this.state.value)

    if (res.length == 0 && this.state.value.length > 0 )
      return this.noresults()

    return <div className={styles.resultsHolder}>
      {res.map( (section) => this.resultset(section) )}
    </div>
  }

  detectKey(charCode: number) {
    if (charCode == 13)
      this.refInput.current.blur()
  }

  resultset(section) {
    return <div key={`section/${section.title}`}>
      {(this.state.searchtype == searchTypes.NAMES)? this.renderSectionTitle(section) : ''}
      {this.suggestions(section)}
    </div>
  }

  renderSectionTitle(section) {
    let title = ''
    if (section.title == SOURCE_AUTHOR)
      title = translate(this.props.anaf.lang, 'artists')
    if (section.title == SOURCE_ARTWORK)
      title = translate(this.props.anaf.lang, 'artworks')

    return (
      <div key={section.title} className={classNames(styles.sectionTitle, "bold")}>{title}</div>
    );
  }

  suggestions(section) {
    return <div>
        {section.searchinput.map((suggestion) => {
          const {source, id, name} = suggestion
          return <ResultTag
            key={`${source}${id}`}
            arrow="right"
            text={name}
            onClick={() => this.onSelected(source, id )}
          />
          })
        }
      </div>
  }

  onChangeValue(value) {
    this.setState({
      value: value,
    })
  }

  onSelected(source, id, title='') {
    this.props.removeAllTags()
    this.setState({
      value: ''
    });

    switch(source){
      case SOURCE_AUTHOR:
        let artist = this.props.data.artists.byIds[id]
        this.props.history.push(ArtistURL(artist))
        break;
      case SOURCE_ARTWORK:
        let artwork = this.props.data.artworks.byIds[id]
        this.props.history.push(ArtworkURL(artwork))
        break;
      case SOURCE_TECHNIQUE:
        this.props.addTag(tagTypes.TECHNIQUE, id, title)
        this.props.history.push('/explore')
        break;
      case SOURCE_COLOR:
        this.props.addTag(tagTypes.COLOUR, id, title)
        this.props.history.push('/explore')
        break;
      case SOURCE_THEME:
        this.props.addTag(tagTypes.THEME, id, title)
        this.props.history.push('/explore')
        break;
      case SOURCE_YEAR:
        this.props.addTag(tagTypes.YEAR, id, title)
        this.props.history.push('/explore')
        break;
      case SOURCE_EXHIBITION:
        this.props.history.push('/exhibition/' + id)
        break;
    }
  }

  noresults() {
    return <div className={classNames(styles.noresults, "bold")}>
      {translate(this.props.anaf.lang, 'noresults')}
    </div>
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Searchbox));
