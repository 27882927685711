import { combineReducers } from "redux";

import {data} from './reducers/dataReducer'
import {anaf} from './reducers/anafReducer'
import {events} from './reducers/eventReducer'
import {news} from './reducers/newsReducer'
import {pages} from './reducers/pageReducer'
export default combineReducers({
  data, anaf, events, pages, news
})
