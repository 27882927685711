import * as React from "react";
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'
import {Helmet} from "react-helmet";

import classNames from "classnames"

import {IData} from "../../reducers/dataReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"

import { Transition } from 'react-transition-group';

import {statusTypes} from "../../actions/actionTypes"

import styles from './styles.module.css'

import {TitlePage} from '../pages/TitlePage'
import {ContainerPage} from '../pages/ContentPage'

import TagCloud from './TagCloud'
import Topsearch from '../header/Topsearch'

import {SVGArrowDown} from '../../tools/svgTools'
import {translate} from '../../tools/translateTools'

interface State {
  page1hit: boolean;
  page2hit: boolean;
  page3hit: boolean;
  page3part2: boolean;
  page3part3: boolean;
  page3part4: boolean;
  moved: boolean;
}

interface parentProps {
  history: any;
  match: any;
  location: any;
}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf,
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

const TIMER = 500

export class Intro extends React.Component<Props, State> {

  constructor(props) {

    super(props);
    this.state = {
      page1hit: false,
      page2hit: false,
      page3hit: false,
      page3part2: false,
      page3part3: false,
      page3part4: false,
      moved: false,
    }

    this.scrolling = this.scrolling.bind(this)
  }

  componentDidMount() {
    this.props.resetCrumbs()
    let home: ICrumb = {title: 'home', link: "/home", bg: "#8bffec"}
    let explore: ICrumb = {title: 'explore', link: "/explore/intro", bg: "#8bffec"}
    let intro: ICrumb = {title: 'intro', link: "/explore/intro", bg: "#8bffec"}

    this.props.addCrumbs([home, explore, intro])

    document.addEventListener("scroll", this.scrolling);
    setTimeout(
      () => this.page1(), TIMER)
  }

  componentDidUpdate() {
    if(this.state.moved)
      return;

    if(this.props.location.hash == "#select") {
      const elem = document.getElementById("select")
      elem && elem.scrollIntoView()
      this.setState({
        moved: true,
      })
    }
  }

  page1(){
    this.setState({
      page1hit: true
    })
  }

  componentWillUnmount(){
    document.removeEventListener("scroll", this.scrolling);
  }

  render() {
    if (!this.haveData())
      return <div>Loading... </div>
    return this.ExploreSelector()
  }

  scrolling() {
    if(!this.state.page2hit) {
      if (this.calcY("page2"))
        this.setState({page2hit: true})
    }

    if(!this.state.page3hit) {
      if (this.calcY("selecttags")){
        this.setState({page3hit: true})
        if(!this.state.page3part2)
          setTimeout(() => this.setPage3part2(), 100)
          setTimeout(() => this.setPage3part3(), 200)
          setTimeout(() => this.setPage3part4(), 210)
      }
    }
  }

  setPage3part2() {
    this.setState({page3part2: true})
  }

  setPage3part3() {
   this.setState({page3part3: true})
  }

  setPage3part4() {
   this.setState({page3part4: true})
  }


  calcY(tag: string) {
    let endpoint = document.getElementById(tag)
    let bodyRect = document.body.getBoundingClientRect()
    let elemRect = endpoint.getBoundingClientRect()
    let offset   = elemRect.top - bodyRect.top
    let wheight  = window.innerHeight
    let delta    = wheight / 4

    return window.scrollY + wheight - delta > offset
  }

  ExploreSelector() {

    const lang = this.props.anaf.lang
    const hiding = this.state.page3hit? styles.hiding : ''

    let buttons = false
    if (this.state.page3part3) {
      buttons = true
    }

    let explore = lang == "EN"? "Filter through techniques, themes and times." : "Filter door technieken, thema&rsquo;s en tijd."
    const tit = translate(lang, 'explore')

    return <div>

      <Helmet>
        <title>AkzoNobel Art Foundation | {tit} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | ${tit}`} />
      </Helmet>

      <ContainerPage bgColour="#8bffec" pageId="p0" next="p1">
        <div className={classNames(styles.hidden, {[`${styles.showing}`] : this.state.page1hit})}>
          <h1 className={styles.exploreText}>{translate(lang, 'explore_collection')}</h1>
        </div>
      </ContainerPage>
      <ContainerPage bgColour="#8bffec" pageId="p1" next="p2" prev="p0" extraWide={true}>
        <div className={classNames(styles.hidden, {[`${styles.showing}`] : this.state.page2hit})}>
          <h1 id="page2" className={styles.exploreText} dangerouslySetInnerHTML={{__html: explore}}></h1>
        </div>
      </ContainerPage>
      <ContainerPage bgColour="#8bffec" pageId="p2" prev="p1" bottom={this.goButton()}>
          <div className={classNames(styles.hidden, {[`${styles.showing}`] : this.state.page3hit})}>
            <h2
              id="selecttags"
              className={classNames(
                  styles.selecttags,
                  {[`${styles.hidden}`] : this.state.page3part2},
                  {[`${styles.void}`] : this.state.page3part3}
                )}>
              {translate(this.props.anaf.lang, 'make_selection')}
            </h2>
            <div
              className={
                classNames(styles.void, styles.hidden,
                  {[`${styles.unvoid}`]: this.state.page3part3},
                  {[`${styles.showing}`]: this.state.page3part4}
                )}>
              <TagCloud />
              <div className={classNames(styles.cloudsearchholder)}>
                {this.props.anaf.cloudsearchOpen? <Topsearch containerClass="cloudsearch"/> : <span></span>}
              </div>
            </div>
          </div>
      </ContainerPage>
      <div id="select"></div>
    </div>
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

  goButton() {
    const tagcount = this.props.anaf.tags.length
    const showButton = tagcount > 0
    const linkable = showButton? 'link' : ''

    return <div
      id="gobutton"
      className={
        classNames(styles.enterHolder,
          'd-flex', 'align-items-end', 'justify-content-center', 'bold', {[`${styles.showButton}`]: showButton})}>
        <div className={`${styles.enter} ${linkable}`} onClick={() => this.generate(showButton)}>
          <div className={styles.text}>{translate(this.props.anaf.lang, 'generate')}</div>
          <div className={styles.arrowHolder}>{SVGArrowDown("#333", {strokeWidth: 4, height: '1.7rem'})}</div>
        </div>
    </div>
  }

  generate(showbutton) {
    if(showbutton)
      this.props.history.push('/explore')
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro));
