import { Reducer } from "redux"
import { statusTypes, actionTypes } from "../actions/actionTypes"

export interface IEevent {
  id: number;
  title: string;
  pressinfo: string;
  address: string;
  moment: string;
  description_en: string;
  description_nl: string;
}

export interface IEventId {
  [id: number]: IEevent;
}

export interface IEevents {
  status: statusTypes;
  eventlist: {
    byIds: IEventId;
  }
}

const initState: IEevents = {
  status: statusTypes.INIT,
  eventlist: {
    byIds: {}
  }
}

export const events: Reducer<IEevents> = (state = initState, action) => {
  switch(action.type) {
    case actionTypes.EVENTS_LOADED: {
      return {
        ...state,
        status: statusTypes.LOADED,
        eventlist: action.payload.eventlist
      }
      break;
    }
  }

  return state
}
