import { Reducer } from "redux"
import { statusTypes, actionTypes } from "../actions/actionTypes"


export interface IPage {
  id: number;
  title_nl: string;
  title_en: string;
  content_nl: string;
  content_en: string;
  slug: string;
  color: number;
  template: string;
}

export interface IPageSlug {
  [id: string]: IPage;
}

export interface IPages {
  status: statusTypes;
  pages: {
    bySlugs: IPageSlug;
  }
}

const initState: IPages = {
  status: statusTypes.INIT,
  pages: {
    bySlugs: {},
  }
}

export const pages: Reducer<IPages> = (state= initState, action) => {
  switch(action.type) {

    case actionTypes.PAGES_LOAD: {
      let newStatus
      if (state.status == statusTypes.LOADED){
        newStatus = statusTypes.UPDATING
      }
      else
        newStatus = statusTypes.LOADING

      return {
        ...state,
        status: newStatus,
      }
    }

    case actionTypes.PAGES_LOADED: {
      return {
        ...state,
        pages: action.payload.pages,
        status: statusTypes.LOADED,
      }
    break;
    }

  }
  return state
}
