export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


export function A_ID_Sort(obj: Object, key: string) {
  return Object.keys(obj).map((id) => obj[id]).sort((a, b) => {
    if(a[key] < b[key])
      return -1
    if (a[key] > b[key])
      return 1
    return 0
  })
}
