import * as React from "react";

import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames"

import {IEevents, IEevent, IEventId} from "../../reducers/eventReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"

import {loadEvents} from "../../actions/eventActions"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {translate} from '../../tools/translateTools'
import {Button} from "../buttons/Button"

import {SVGPlus, SVGCross, SVGArrowRight} from "../../tools/svgTools"
import styles from './styles.module.css'

interface State {
  selected: number;
  maxItems: number;
}

interface parentProps {
}

interface StoreProps {
  events: IEevents;
  anaf: IAnaf;
}

interface DispatchProps {
  loadEvents: Function;
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    events: state.events,
    anaf: state.anaf,
  }
};

const mapDispatchToProps = dispatch => ({
  loadEvents: () => { dispatch( loadEvents())},
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Events extends React.Component<Props, State> {
  componentDidMount() {
    this.props.loadEvents();
    this.props.resetCrumbs()

    let home: ICrumb = {title: 'home', link: "/home", bg: "#ffe6aa"}
    let events: ICrumb = {title: 'events', link: "/events", bg: "#ffe6aa"}

    this.props.addCrumbs([home, events])
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: -1,
      maxItems: 5,
    }
  }

  render() {

    return <div className={`${styles.page} content`}>

      <Helmet>
        <title>AkzoNobel Art Foundation | Events </title>
        <meta name="description" content={`AkzoNobel Art Foundation | Events`} />
      </Helmet>

      <h1 className={classNames("secundair", styles.mainTitle)}>Events</h1>
      <div className={styles.border}></div>
      {this.limited().map((item) => this.eventLine(item))}
      {this.loadMore()}
    </div>
  }

  loadMore() {
    const lang = this.props.anaf.lang

    if (this.events().length > this.state.maxItems)
      return <div onClick={() => this.updateMax()}>
        <Button arrow="right" color="#ffe6aa" text={translate(lang, 'load_more')}/>
      </div>

    return <span></span>
  }

  eventLine(event) {
    const buttonStyle = {
      width: '60px',
    }

    const contentStyle = {
      display: (this.state.selected == event.id)? 'flex' : 'none'
    }

    const lang = this.props.anaf.lang
    const show = this.state.selected == event.id

    return <div key={event.id}>
      <div className="row link" onClick={() => this.toggleEvent(event.id)}>
        <div className={` ${styles.moment} col-3 bold col-sm-3`}>{event.moment}</div>
        <div className={classNames('col-9', 'col-sm-1', 'text-right', styles.crossholder, {[`${styles.rotate}`]: show} )}>
          {SVGPlus('#333')}
        </div>
        <div className={`${styles.title} col-12 col-sm-8 bold`}>{event.title}</div>
      </div>
      <div style={contentStyle} className="row">
        <div className="col-4 d-none d-md-inline">
          <img className="img-fluid" src={event.image} />
        </div>
        <div className={`col-12 col-md-8 ${styles.content}`} dangerouslySetInnerHTML={{ __html: lang == "EN" ? event.description_en : event.description_nl }}></div>
      </div>
      <div style={contentStyle} className="row">
        <div className={`${styles.address} col-5 col-md-4 `}>{event.address}</div>
        <div className={`bold col-7 col-md-8 d-flex align-items-end pl-0`}>
          {(event.pressinfo)?
          <div className={styles.infobutton}><a
            href={event.pressinfo}
            target="_blank"
            onClick= {(e) =>{e.stopPropagation()}}>
            <Button arrow="right" color="#ffe6aa" text="Press info"/>
          </a></div> : <div></div>}
        </div>
      </div>
      <div className="row">
        <div className={`col-4 ${styles.borderholder}`}>
          <div className={`${styles.border}`}></div>
        </div>
        <div className={`col-8 pl-0`}>
          <div className={`${styles.border}`}></div>
        </div>
      </div>

    </div>
  }


  toggleEvent(id) {
    this.setState({
      selected: (this.state.selected == id)? 0 : id,
    })
  }

  limited() {
    let items: any = this.events();
    const max = items.length;

    return items.slice(0, Math.min(this.state.maxItems, max))
  }

  events() {
   const events: IEventId = this.props.events.eventlist.byIds

    let unsorted = new Array()
    unsorted = Object.keys(events).map((id) => events[id])
    unsorted.sort((a,b) => {
      let am = a.moment.substring(6, 10) + a.moment.substring(3, 5) + a.moment.substring(0, 2)
      let bm = b.moment.substring(6, 10) + b.moment.substring(3, 5) + b.moment.substring(0, 2)

      if (am < bm)
        return 1
      if (am > bm)
        return -1;
      return 0;
    })

    return unsorted
  }

  updateMax() {
    this.setState({
      maxItems: this.state.maxItems + 5,
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
