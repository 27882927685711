import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router-dom'
import {Helmet} from "react-helmet";

import * as CONST from "./consts"

import {statusTypes} from "../../actions/actionTypes"

import {IData} from "../../reducers/dataReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {ErrorBoundary} from "../ErrorBoundary"
import {translate} from '../../tools/translateTools'
import {colorlist} from '../../tools/colorTools'
import {stripMarks} from '../pages/ContentPage'

import ArtworkList from './ArtworkList'

interface State {
  path: string;
}

interface parentProps {
  match: any;
  location: any;
}

interface StoreProps {
  data: IData;
  dataStatus: statusTypes;
  lang: string
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
    lang: state.anaf.lang,
  }
}

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Exhibition extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      path: '',
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if(props.location.pathname != state.path){
      if (props.data.status > statusTypes.LOADING) {
        const exhibition = props.data.exhibitions.byIds[props.match.params.id]
        const c = colorlist()[exhibition.colour]
        const color = `rgb(${c.r},${c.g},${c.b})`
        props.resetCrumbs()

        let home: ICrumb = {title: 'home', link: "/home", bg: "#f1f1f2"}
        const link = `/exhibition/${exhibition.id}/0`

        if (exhibition.etype == CONST.HIGHLIGHT) {
          let explore: ICrumb = {title: 'explore', link: "/explore", bg: "#f1f1f2"}
          let highlight: ICrumb = {title: 'highlights', link: "/highlights", bg: "#f1f1f2"}
          let exhibitionName: ICrumb = {title: {en: exhibition.hname, nl: exhibition.hname}, link: link, bg: color}
          props.addCrumbs([home, explore, highlight, exhibitionName])
        }
        else if (exhibition.etype == CONST.THEME) {
          let exhibitionName: ICrumb = {title: {en: exhibition.hname, nl: exhibition.hname}, link: link, bg: color}
          props.addCrumbs([home, exhibitionName])
        }
        else {
          if (exhibition.active) {
            let artspace: ICrumb = {title: 'art_space', link: link , bg: "#f1f1f2"}
            let exhibitionName: ICrumb = {title: {en: exhibition.title_en, nl: exhibition.title_nl}, link: link, bg: color}
            props.addCrumbs([home, artspace, exhibitionName])
          }
          else {
            let exhibitions: ICrumb = {title: 'past_exhibitions', link: "/exhibitions", bg: "#f1f1f2"}
            let exhibitionName: ICrumb = {title: {en: exhibition.title_en, nl: exhibition.title_nl}, link: link, bg: color}
            props.addCrumbs([home, exhibitions, exhibitionName])
          }
        }
        return {
          path: props.location.pathname,
        }
      }
    }

    return null;
  }

  render() {
    if(!this.haveData()) {
      return <div>Loading...</div>
    }

    const e = this.props.data.exhibitions.byIds[this.props.match.params.id]
    const page = this.props.match.params.page || 0

    let tit = stripMarks((this.props.lang == "EN")? e.title_en : e.title_nl)
    let section = this.sectionName(e)

    return <div style={{backgroundColor: '#f1f1f2', paddingBottom: 140}}>
      <Helmet>
        <title>AkzoNobel Art Foundation | {section} | {tit} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | {section} | ${tit}`} />
      </Helmet>

      <ArtworkList  artwork_ids={e.artworks} eid={e.id} page={page}/>
    </div>
    }

    sectionName(e) {
      if (e.etype == CONST.HIGHLIGHT)
        return "Highlights"

      if (e.etype == CONST.THEME)
        return translate(this.props.lang, "theme")

      if (e.active)
        return translate(this.props.lang, "art_space")

      return translate(this.props.lang, "past_exhibitions")
    }

    haveData() {
      const s = this.props.data.status
      return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exhibition));
