export enum actionTypes {
  ARTWORKS_LOAD,
  ARTWORKS_LOADED,
  ANAF_INIT_ANAF,
  ANAF_UPDATEDIMENSIONS,
  ANAF_UPDATE_LANG,
  ANAF_ADD_TAG,
  ANAF_REMOVE_TAG,
  ANAF_REMOVE_ALL_TAGS,
  ANAF_RESET_CRUMBS,
  ANAF_ADD_CRUMBS,
  ANAF_LIGHTBOX_OPEN,
  ANAF_LIGHTBOX_CLOSE,
  ANAF_LIGHBOX_SET_INDEX,
  ANAF_SEARCH_TOGGLE,
  ANAF_TOP_SEARCH,
  ANAF_CLOUD_SEARCH,
  ANAF_CONTACT_SUCCESS,
  EVENTS_LOAD,
  EVENTS_LOADED,
  NEWS_LOAD,
  NEWS_LOADED,
  PAGES_LOAD,
  PAGES_LOADED,
}

export enum statusTypes {
  INIT,
  LOADING,
  LOADED,
  UPDATING,
}

export enum tagTypes {
  TECHNIQUE,
  YEAR,
  ARTWORK,
  COLOUR,
  THEME,
}

export enum layoutTypes {
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  CENTER,
  NONE,
  VIDEO,
}
