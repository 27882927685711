import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { actionTypes } from "./actionTypes"

export function loadNews() {
  return function(dispatch) {
    dispatch({type: actionTypes.NEWS_LOAD});

    axios.get("/home/newsitems.json")
      .then((response) => {
        dispatch({type: actionTypes.NEWS_LOADED, payload: response.data})
      });
  }
}
