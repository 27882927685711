export function pColour(colours) {
  if (colours == 0)
    return "rgb(255,255,255)"
  const c = colours[0]
  return "rgb(" + c.colour + ")"
}

export function pColourA(colours) {
  if (colours == 0)
    return "rgba(255,255,255)"
  const c = colours[0]
  return "rgba(" + c.colour + ",0)"
}

export function textColour(colours, invert = false) {
  if (colours == 0)
    return "#333"

  const c = colours[0].colour
  const parts = c.split(",")
  const  r = parseInt(parts[0]);
  const  g = parseInt(parts[1]);
  const  b = parseInt(parts[2]);

  if (!invert)
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#333'
          : '#FFF';
  else
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#FFF'
          : '#333';

}

export function colourTags() {
  let c = new Array()
  c[0] = {nl: 'Zwart', en: 'Black'}
  c[1] = {nl: 'Bruin', en: 'Brown'}
  c[2] = {nl: 'Paars', en: 'Purple'}
  c[3] = {nl: 'Rood', en: 'Red'}
  c[4] = {nl: 'Oranje', en: 'Orange'}
  c[5] = {nl: 'Groen', en: 'Green'}
  c[6] = {nl: 'Blauw', en: 'Blue'}
  c[7] = {nl: 'Grijs', en: 'Grey'}
  c[8] = {nl: 'Roze', en: 'Pink'}
  c[9] = {nl: 'Geel', en: 'Yellow'}
  c[10] = {nl: 'Wit', en: 'White'}
  c[11] = {nl: 'Beige', en: 'Beige'}
  c[12] = {nl: 'Zilver', en: 'Silver'}
  c[13] = {nl: 'Goud', en: 'Gold'}
  return c
}

export function colourFlags(flags: number) {
  let tag_ids = new Array()
  let tags = colourTags()

  tags.map((tag, index) => {
    let flag = Math.pow(2, index)
    if((flag & flags) > 0)
      tag_ids.push(index)
  })
  return tag_ids;
}

export function colorlist() {
  let list = [
    {r: 255, g: 90, b: 160},
    {r: 139, g: 255, b: 0},
    {r: 255, g: 80, b: 50},
    {r: 230, g: 200, b: 0},
    {r: 100, g: 100, b: 250},
    {r: 70, g: 180, b: 0},
    {r: 139, g: 255, b: 236},
    {r: 0, g: 0, b: 0},
    { r: 255, g: 205, b: 225 },
    { r: 220, g: 255, b: 250 },
    { r: 255, g: 200, b: 190 },
    { r: 255, g: 245, b: 170 },
    { r: 210, g: 210, b: 255 },
    { r: 195, g: 255, b: 155 },
  ]
  return list
}

export function lightColorlist() {
  let list = [
    '#ffcde1',
    '#dcfffa',
    '#ffc8be',
    '#fff5aa',
    '#d2d2ff',
    '#c3ff9b',
    '#ff5aa0',
    '#8bff00',
    '#ff5032',
    '#e6c800',
    '#6464fa',
    '#46b400',
    '#000',
  ]
  return list
}
