import * as React from "react";

import {connect} from "react-redux"

import {Link} from "react-router-dom";
import {IData} from "../../reducers/dataReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {Helmet} from "react-helmet";

import ClassNames from 'classnames'

import {statusTypes} from "../../actions/actionTypes"
import {pColour, textColour} from '../../tools/colorTools'

import {SimpleContainerPage, QuotePage} from '../pages/ContentPage'
import MasonryList from "../masonry/MasonryList"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {ScrollHolder} from '../pages/Scrolling'

import {ArtistURL, ArtworkURL} from '../../tools/pageTools'
import {SVGArrowRight} from '../../tools/svgTools'
import {shuffle} from '../../tools/generalTools'
import styles from './artist.module.css';

import {translate} from '../../tools/translateTools'

interface State {
  path: string;
}

interface parentProps {
  match: any;
  location: any;
}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf,
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Artwork extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      path: '',
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if(props.location.pathname != state.path){
      if (props.data.status > statusTypes.LOADING) {
        const artwork = props.data.artworks.byIds[props.match.params.id]
        const artist = props.data.artists.byIds[artwork.artist_id]
        props.resetCrumbs()

        let home: ICrumb = {title: 'home', link: "/home", bg: "#f1f1f2"}
        let explore: ICrumb = {title: 'explore', link: "/explore", bg: "#f1f1f2"}
        let artistname: ICrumb = {title: {en: artist.name, nl: artist.name}, link: ArtistURL(artist), bg: artist.color}
        let artworkname: ICrumb = {title: {en: artwork.title, nl: artwork.title}, link: ArtworkURL(artwork), bg: artist.color}
        props.addCrumbs([home, explore, artistname, artworkname])

        return {
          path: props.location.pathname,
        }
      }
    }

    return null;
  }

  render() {
    if (!this.haveData())
      return <div> loading </div>


    const artwork = this.props.data.artworks.byIds[this.props.match.params.id]
    const artist = this.props.data.artists.byIds[artwork.artist_id]

    let aw = artwork.id
    if (artwork.part_of_set)
      aw = artwork.subset[0]


    return <div>

      <Helmet>
        <title>AkzoNobel Art Foundation | {artist.name} - {artwork.title}</title>
        <meta name="description" content={`AkzoNobel Art Foundation | ${artist.name} - ${artwork.title} `} />
      </Helmet>

      <div className={styles.topMargin}></div>
      <MasonryList artwork_ids={[aw]} solo={true}/>
      {this.linkAuthor(artist)}
    </div>
  }

  linkAuthor(artist) {
    return <div className={ClassNames(styles.ymalHolder, 'bold')}>
      <h2>{translate(this.props.anaf.lang, 'more_from_this_artist')}</h2>
      <div className={styles.tagHolder}>
        <Link key={artist.id} className="bold link" to={ArtistURL(artist)}>
          <div className={styles.tag}>
            {artist.name} <div>{SVGArrowRight('#333', {strokeWidth: 3})}</div>
          </div>
        </Link>
      </div>
    </div>
  }


  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Artwork);
