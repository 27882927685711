export function techniqueList() {

  let t = new Array();
  t[1] = {nl: 'Schilderij', en: 'Painting'}
  t[2] = {nl: 'Werk op papier', en: 'Work on paper'}
  t[3] = {nl: 'Grafiek', en: 'Graphic arts'}
  t[4] = {nl: 'Sculptuur', en: 'Sculpture'}
  t[5] = {nl: 'Fotografie', en: 'Photography'}
  t[6] = {nl: 'Gemengde techniek', en: 'Mixed media'}
  t[7] = {nl: 'Videokunst', en: 'Video art'}
  t[8] = {nl: 'Textilia', en: 'Textilia'}

  return t;
}
