import * as React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

// import {IAnaf} from "../../reducers/anafReducer"

import ReactMapGL from 'react-map-gl';

import styles from './styles.module.css'

interface State {
  viewport: any;
  mounted: boolean;
}

interface parentProps {
  history: any;
}

interface StoreProps {
  // anaf: IAnaf;
}

interface DispatchProps {
}

const mapStateToProps = state => {
  return {
    // anaf: state.anaf
  }
}

const mapDispatchToProps = dispatch => ({
});

type Props = StoreProps & DispatchProps & parentProps


export class Map extends React.Component<Props, State> {
  refs: any;

  constructor(props) {
    super(props);

    this.state = {
      viewport: {
        width: '45vw',
        height: '40vh',
        latitude: 52.340117,
        longitude: 4.877868,
        zoom: 18,
      },
      mounted: false,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.updateMapWidth());
    this.updateMapWidth()
    this.setState({
      mounted: true,
    })

    setTimeout(
      () => this.updateMapWidth(), 300)

  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateMapWidth());
    this.setState({
      mounted: false,
    })
  }

  updateMapWidth() {
    let w = this.refs.child.parentNode.clientWidth

    this.setState({
      viewport: {
        ...this.state.viewport,
        width: w,
      }
    })
  }

  render() {

    const token = "pk.eyJ1Ijoid2F4bGUiLCJhIjoiY2o4b241d3hjMDVnazJ2cGNkamN5MHR5cSJ9.iSjiyYeyfysSRZ3etEweSA" //#18/52.340117/4.877868
    //const token = "pk.eyJ1Ijoid2llZ2VyaG9mc3RyYSIsImEiOiJjanZ5MThhc2gwYTdqNDhxcm84Y3lsZ2U4In0.vZa490olvqbwrq5MBmoQfg"
    return  <div className={styles.mapholder}>
      <div ref="child">
      <ReactMapGL {...this.state.viewport}
        mapboxApiAccessToken={token}
        onViewportChange={(viewport) => this.setState({viewport})}
        mapStyle={'mapbox://styles/waxle/cjvnwu0dr0ajq1cn4nl8enr21'}
      /></div></div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
