import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { actionTypes } from "./actionTypes"

export function loadEvents() {
  return function(dispatch) {
    dispatch({type: actionTypes.EVENTS_LOAD});

    axios.get("/home/events.json")
      .then((response) => {
        dispatch({type: actionTypes.EVENTS_LOADED, payload: response.data})
      });
  }
}
