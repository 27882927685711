import { Reducer } from "redux";
import { statusTypes, actionTypes } from "../actions/actionTypes";

interface IColour {
  colour: string;
  tag: number;
}

export interface IArtwork {
  id: number;
  title: string;
  artist_id: number;
  width: number;
  height: number;
  dim_width: number;
  dim_height: number;
  dim_depth: number;
  image: string;
  colours: Array<IColour>;
  technique_id: number;
  technique_nl: string;
  technique_en: string;
  description_nl: string;
  description_en: string;
  year: number;
  part_of_set: boolean;
  subset: number[];
  ctags: number;
  exhibitions: number[];
  on_location: number;
}

export interface IArtworkId {
  [id: number]: IArtwork;
}

export interface IArtist {
  id: number;
  name: string;
  surname: string;
  birth_place: string;
  birth_year: string;
  country_nl: string;
  country_en: string;
  bio_short: string;
  bio_nl: string;
  bio_en: string;
  bio_short_nl: string;
  bio_short_en: string;
  website: string;
  artworks: number[];
  published: boolean;
  quote_nl: string;
  quote_footer_nl: string;
  quote_en: string;
  quote_footer_en: string;
  color: string;
}

export interface IArtistId {
  [id: number]: IArtist;
}

interface ITag {
  artworks: number[];
}
export interface ITagId {
  [id: number]: ITag;
}

interface IAnnotation {
  id: number;
  nl: string;
  en: string;
}

interface IAnnotationId {
  [id: number]: IAnnotation;
}

export interface IExhibition {
  id: number;
  title_nl: string;
  title_en: string;
  description_nl: string;
  description_en: string;
  description_preview_nl: string;
  description_preview_en: string;
  artworks: number[];
  colour: number;
  active: boolean;
  annotate: IAnnotationId;
  video: string;
  quote_footer_nl: string;
  quote_footer_en: string;
  opened_at: string;
  etype: number;
  image: string;
  image_author: string;
  hname: string;
  hfunction_nl: string;
  hfunction_en: string;
  hlocation: string;
  tour: boolean;
}

export interface IExhibitionId {
  [id: number]: IExhibition;
}

export interface IData {
  status: statusTypes;
  artworks: {
    byIds: IArtworkId;
    byTags: ITagId;
    years: number[];
  };
  artists: {
    byIds: IArtistId;
  };
  stats: {
    artwork_count: number;
    artist_count: number;
  };
  exhibitions: {
    byIds: IExhibitionId;
  };
  explore: string;
}

const initState: IData = {
  status: statusTypes.INIT,
  artworks: {
    byIds: {},
    byTags: {},
    years: [],
  },
  artists: {
    byIds: {},
  },
  stats: {
    artwork_count: 0,
    artist_count: 0,
  },
  exhibitions: {
    byIds: {},
  },
  explore: "",
};

export const data: Reducer<IData> = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ARTWORKS_LOAD: {
      let newStatus;
      if (state.status == statusTypes.LOADED) newStatus = statusTypes.UPDATING;
      else newStatus = statusTypes.LOADING;

      return {
        ...state,
        status: newStatus,
      };
    }

    case actionTypes.ARTWORKS_LOADED: {
      return {
        ...state,
        status: statusTypes.LOADED,
        artworks: action.payload.artworks,
        artists: action.payload.artists,
        stats: action.payload.stats,
        exhibitions: action.payload.exhibitions,
        explore: action.payload.explore,
      };
      break;
    }
  }
  return state;
};
