import * as React from "react";
import {connect} from "react-redux"

import {IArtwork, IArtist, IExhibition} from "../../reducers/dataReducer"
import {IAnaf} from "../../reducers/anafReducer"

import {openLightbox} from "../../actions/anafActions"

import {pColour, textColour} from '../../tools/colorTools'
import {SVGFullscreen, SVGArrowRight, SVGArrowLeft} from '../../tools/svgTools'

import { Transition } from 'react-transition-group';

import styles from './EAstyles.module.css';

interface State {
  isFlipped: boolean;
  showButtons: boolean;
  mounted: boolean;
  loaded: boolean;
  sindex: number;
}

interface parentProps {
  artwork_id: number;
  index: number;
}

interface StoreProps {
  anaf: IAnaf;
  artworks: IArtwork[];
  artists: IArtist[];
  exhibitions: IExhibition[];
}

interface DispatchProps {
  openLightbox: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf,
    artworks: state.data.artworks.byIds,
    artists: state.data.artists.byIds,
  }
}

const mapDispatchToProps = dispatch => ({
  openLightbox: (index: number, sindex: number) => {dispatch( openLightbox(index, sindex))},
});

type Props = StoreProps & DispatchProps & parentProps


export class EArtwork extends React.Component<Props, State> {

  refs: any;
  artwork: IArtwork;
  artist: IArtist;

  constructor(props) {
    super(props);

    this.artwork = this.props.artworks[this.props.artwork_id]
    this.artist = this.props.artists[this.artwork.artist_id]

    this.state = {
      mounted: false,
      isFlipped: false,
      showButtons: false,
      loaded: false,
      sindex: 0,
    }
  }

   componentDidMount() {
     let w = this.refs.child.parentNode.clientWidth
     this.setState({
       mounted: true,
     })
   }

   componentWillUnmount() {
     this.setState({
       mounted: false,
     })
   }

  render() {
    const a = this.artwork.subset? this.props.artworks[this.artwork.subset[this.state.sindex]] : this.artwork

    let maxw = 100
    if (this.state.mounted) {
      maxw = this.refs.child.parentNode.clientWidth - 100
    }
    let maxh = this.props.anaf.height * 0.6;
    if (a.part_of_set)
      maxh -= 25


    let w = 100;
    let h = 100;

    let scale = a.width / maxw
    let newh = a.height / scale
    if (newh > maxh) {
      scale = a.height / maxh
      w = a.width / scale
      h = a.height / scale
    } else {
      w = maxw
      h = a.height / scale
    }

    const img = {
      width: w,
      height: h,
      minHeight: 100,
      backgroundColor: pColour(this.artwork.colours),
      backgroundImage: `url("${this.image()}")`,
      backgroundSize: 'cover',
      zIndex: this.state.isFlipped? 10 : 0,
      margin: 'auto',
    }

    return <div className="d-flex flex-column" style={{width: '100%'}}>
      <div ref="child" className="d-flex justify-content-center" style={{height: '60vh'}}>
        <div className={`link d-flex ${styles.bgimg}`} style={img} onClick={(e) => this.doFlip(e)}>
          {this.backside(w, h)}
        </div>
      </div>
      {a.part_of_set? this.setnav(w) : <span></span>}
    </div>
  }

  preload(){
    return <div style={{display: 'none'}}>
    {this.artwork.subset.map(id =>
      <img key={id} src={this.props.artworks[id].image}/>
    )}
    </div>
  }

  image() {
    let a = this.artwork
    if (!a.part_of_set)
      return this.artwork.image
    let id = this.artwork.subset[this.state.sindex]
    let artwork = this.props.artworks[id]
    return artwork.image
  }

  imageLoaded() {
    this.setState({
      loaded: true
    })

    setTimeout(
      () => this.setVisible(), 2500)
  }

  setnav(w){
    let c = this.state.sindex
    let size = this.artwork.subset.length

    const arrowStyle = {
      strokeWidth: 3,
      width: 12,
      float: 'right',
      paddingTop: 5,
    }
    return <div className={`d-flex justify-content-center ${styles.subnavholder}`}>
      <div className="link" onClick={() => this.prevSub()}>{SVGArrowLeft('#333', arrowStyle)}</div>
      <div className={`bold ${styles.subnav}`}>{c + 1} / {size}</div>
      <div className="link" onClick={() => this.nextSub()}>{SVGArrowRight('#333', arrowStyle)}</div>
      <div>{this.preload()}</div>
    </div>
  }

  backside(w, h) {
    const duration = 350;

    let backStyle = {
      width: w,
      minHeight: 0,
      height: 0,
      backgroundColor: pColour(this.artwork.colours),
      color: textColour(this.artwork.colours),
      textAlign: 'left' as 'left',
      overflow: 'hidden',
      transition: `min-height ${duration}ms ease-in-out`,

    }

    const transitionStyles = {
      entering: { minHeight: h,
        height: 0,
       },
      entered:  { minHeight: h,
        height: 'auto',
        overflow: 'auto'
      },
      exiting:  {
       minHeight: 0,
       height: 0,
       overflow: 'hidden'
      },
      exited:  {
        minHeight: 0,
        height: 0,
        overflow: 'hidden'
      },
    };

    return <div key="back">
      <Transition in={this.state.isFlipped} timeout={duration}>
      {sx => (

      <div style={{...backStyle, ...transitionStyles[sx]}} className={`d-flex flex-column back`}>
        <div className="p-4">
          {this.title()}
        </div>
        <div className="mt-auto ml-auto">
          <div style={{cursor: 'pointer', padding: '24px' }}
              onClick={ (e) => this.openLightbox(e)}>{
                SVGFullscreen(
                  textColour(this.artwork.colours),
                  {width: 40, strokeWidth: 1})}
          </div>
        </div>
      </div>
    )}
    </Transition>

    </div>
  }

  title() {
    return <div className={`bold ${styles.title}`}>
      {this.artist.name}<br />
      {this.artwork.title}<br />
      {this.artwork.year}
    </div>
  }

  doFlip(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  setVisible() {
   this.setState(prevState => ({ isFlipped: true }));
  }

  openLightbox(e) {
    this.props.openLightbox(this.props.index, this.state.sindex)
    e.stopPropagation();
  }

  nextSub() {
    let next = (this.state.sindex + 1) % this.artwork.subset.length
    this.setState({
      sindex: next,
    })
  }

  prevSub() {
    let prev = (this.artwork.subset.length + this.state.sindex - 1) % this.artwork.subset.length
    this.setState({
      sindex: prev,
    })
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(EArtwork);
