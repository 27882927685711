import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { actionTypes } from "./actionTypes"

export function loadArtworks() {
  return function(dispatch) {
    dispatch({type: actionTypes.ARTWORKS_LOAD});

    axios.get("/home/artworks.json")
      .then((response) => {
        dispatch({type: actionTypes.ARTWORKS_LOADED, payload: response.data})
      });
  }
}

export function loadPages() {
  return function(dispatch) {
    dispatch({type: actionTypes.PAGES_LOAD});

    axios.get("/home/pages.json")
      .then((response) => {
        dispatch({type: actionTypes.PAGES_LOADED, payload: response.data})
      });
  }
}
