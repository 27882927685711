import * as React from "react";

import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames"

import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {statusTypes} from "../../actions/actionTypes"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {translate} from '../../tools/translateTools'

import {SVGArrowRight} from "../../tools/svgTools"
import styles from './styles.module.css'

interface State {
  maxItems: number;
}

interface parentProps {
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf,
  }
};

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class About extends React.Component<Props, State> {
  componentDidMount() {
    this.props.resetCrumbs()
    let home: ICrumb = {title: 'home', link: "/home", bg: "#c89696"}
    let about: ICrumb = {title: 'about_us', link: "/about_us", bg: "#c89696"}
    this.props.addCrumbs([home, about])
  }

  constructor(props) {
    super(props)
    this.state = {
      maxItems: 50,
    }
  }

  render() {

    if(!this.haveData()) {
      return <div>Loading...</div>
    }
    const tit = translate(this.props.anaf.lang, 'about_us')
    return <div className={`${styles.page} content`}>
      <Helmet>
        <title>AkzoNobel Art Foundation | {tit} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | ${tit}`} />
      </Helmet>

      <h1 className={classNames("secondair", styles.mainTitle)}>{tit}</h1>
      {this.props.anaf.dataset.menus[2].map((item, index) => this.eventLine(item, index))}
    </div>
  }



  eventLine(item, index) {
    console.log(item, index)
    const lang = this.props.anaf.lang

    return <div key={index}>
      <Link to={item.stub}>
      <div className="row link">
        <div className={`${styles.title} ${styles.border} col-10 col-md-5 bold offset-md-3 `}>
          {lang == "EN" ? item.title_en : item.title_nl}
        </div>
        <div className={`${styles.icon} ${styles.border} col-2 col-md-1 pr-0`}>
        <div className={styles.iconHolder}>{SVGArrowRight('#333')}</div>
        </div>
      </div>
      </Link>
    </div>
  }

  haveData() {
    const s = this.props.anaf.dataset.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
