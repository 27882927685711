import * as React from "react";

import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames"


import {INewsitems, INewsitem, INewsitemId} from "../../reducers/newsReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"

import {loadNews} from "../../actions/newsActions"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {translate} from '../../tools/translateTools'

import {Button} from "../buttons/Button"

import {SVGPlus, SVGCross, SVGArrowDown} from "../../tools/svgTools"
import styles from './styles.module.css'

interface State {
  selected: number;
  maxItems: number;
}

interface parentProps {
}

interface StoreProps {
  news: INewsitems;
  anaf: IAnaf;
}

interface DispatchProps {
  loadNews: Function;
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    news: state.news,
    anaf: state.anaf,
  }
};

const mapDispatchToProps = dispatch => ({
  loadNews: () => { dispatch( loadNews())},
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class News extends React.Component<Props, State> {
  componentDidMount() {
    this.props.loadNews();
    this.props.resetCrumbs()
    let home: ICrumb = {title: 'home', link: "/home", bg: "#f1f1f2"}
    let media: ICrumb = {title: 'in_the_media', link: "/in_the_media", bg: "#f1f1f2"}
    this.props.addCrumbs([home, media])
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: -1,
      maxItems: 5,
    }
  }

  render() {
    const eid = this.props.news.newslist.byIds
    const tit = translate(this.props.anaf.lang, 'in_the_media')

    return <div className={`${styles.page} content`}>

      <Helmet>
        <title>AkzoNobel Art Foundation | {tit} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | ${tit}`} />
      </Helmet>

      <h1 className={classNames("secundair", styles.mainTitle)}>{tit}</h1>
      <div className={styles.border}></div>
      {this.limited().map((item) => this.eventLine(item))}
      {this.loadMore()}
    </div>
  }

  loadMore() {
    const lang = this.props.anaf.lang

    if (this.items().length > this.state.maxItems)
      return <div onClick={() => this.updateMax()}>
        <Button arrow="down" color="#f1f1f2" text={translate(lang, 'load_more')}/>
      </div>

    return <span></span>
  }

  eventLine(item) {
    const buttonStyle = {
      width: '60px',
    }

    const iconStyle = {
      width: '18px',
      display: 'inline-block',
    }

    const contentStyle = {
      display: (this.state.selected == item.id)? 'flex' : 'none'
    }

    const lang = this.props.anaf.lang
    const show = this.state.selected == item.id

    return <div key={item.id}>
      <div className="row link" onClick={() => this.toggleItem(item.id)}>
        <div className={` ${styles.moment} col-3 bold col-sm-3`}>{item.moment}</div>
        <div className={classNames('col-9', 'col-sm-1', 'text-right', styles.crossholder, {[`${styles.rotate}`]: show} )}>
          {SVGPlus('#333')}
        </div>
        <div className={`${styles.title} col-12 col-sm-8 bold`}>{lang == "EN" ? item.title_en : item.title_nl}</div>
      </div>
      <div style={contentStyle} className="row">
        <div className="col-4 d-none d-md-inline">
          <img className="img-fluid" src={item.image} />
        </div>
        <div className={`col-12 col-md-8 ${styles.content}`} dangerouslySetInnerHTML={{ __html: lang == "EN" ? item.description_en : item.description_nl }}></div>
      </div>
      <div style={contentStyle} className="row">
        <div className={`${styles.address} col-4 `}>{item.address}</div>
        <div className={`bold col-8 d-flex align-items-end pl-0`}>
          <div className={styles.infobutton}><a
            href={item.pressinfo}
            target="_blank"
            onClick= {(e) =>{e.stopPropagation()}}>
            <Button arrow="right" color="#f1f1f2" text="Press info"/>
          </a></div>
        </div>
      </div>
      <div className="row">
        <div className={`col-4 ${styles.borderholder}`}>
          <div className={`${styles.border}`}></div>
        </div>
        <div className={`col-8 pl-0`}>
          <div className={`${styles.border}`}></div>
        </div>
      </div>

    </div>
  }


  toggleItem(id) {
    this.setState({
      selected: (this.state.selected == id)? 0 : id,
    })
  }

  limited() {
    let items: any = this.items();
    const max = items.length;

    return items.slice(0, Math.min(this.state.maxItems, max))
  }


  items() {
   const items: INewsitemId = this.props.news.newslist.byIds

    let unsorted = new Array()
    unsorted = Object.keys(items).map((id) => items[id])
    unsorted.sort((a,b) => {
      let am = a.moment.substring(6, 10) + a.moment.substring(3, 5) + a.moment.substring(0, 2)
      let bm = b.moment.substring(6, 10) + b.moment.substring(3, 5) + b.moment.substring(0, 2)

      if (am < bm)
        return 1
      if (am > bm)
        return -1;
      return 0;
    })

    return unsorted
  }

  updateMax() {
    this.setState({
      maxItems: this.state.maxItems + 5,
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(News);
