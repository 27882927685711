import { Reducer } from "redux"
import { statusTypes, actionTypes } from "../actions/actionTypes"

export interface INewsitem {
  id: number;
  title_nl: string;
  title_en: string;
  pressinfo: string;
  moment: string;
  description_en: string;
  description_nl: string;
}

export interface INewsitemId {
  [id: number]: INewsitem;
}

export interface INewsitems {
  status: statusTypes;
  newslist: {
    byIds: INewsitemId;
  }
}

const initState: INewsitems = {
  status: statusTypes.INIT,
  newslist: {
    byIds: {}
  }
}

export const news: Reducer<INewsitems> = (state = initState, action) => {
  switch(action.type) {
    case actionTypes.NEWS_LOADED: {
      return {
        ...state,
        status: statusTypes.LOADED,
        newslist: action.payload.newslist
      }
      break;
    }
  }

  return state
}
