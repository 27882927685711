export function scrollTo(height) {
  window.scrollTo({
    top: height,
    behavior: "smooth"
  })
}

export function  scrollToID(id, delta = 0) {
   let endpoint = document.getElementById('page_' + id)
   let bodyRect = document.body.getBoundingClientRect()
   let elemRect = endpoint.getBoundingClientRect()
   let offset   = elemRect.top - bodyRect.top
    window.scrollTo({
      top: offset + delta,
      behavior: "smooth"
    })
  }

export function ArtistURL(artist) {
  const stub = encodeURIComponent(artist.name.replace(/ /gi,'_'))
  return '/artist/' + artist.id + '/' + stub
}


export function ExhibionURL(exhibition) {
  const stub = encodeURIComponent(exhibition.title_en.replace(/ /gi,'_'))
  return '/exhibition/' + exhibition.id
}


export function ArtworkURL(artwork) {
  const stub = encodeURIComponent(artwork.title.replace(/ /gi,'_'))
  return '/artwork/' + artwork.id + '/' + stub
}


export function onTourPage(path) {
  return path.substring(0,5) == "/tour"
}
