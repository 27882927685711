import * as React from "react";

export function SVGPlus(colour: string, style = {}) {
  return <svg viewBox="0 0 72.8 72.8"  style={style}>
      <g style={{fill: colour}} stroke={colour}>
        <polygon
          points="72.8,33.9 38.9,33.9 38.9,0 33.9,0 33.9,33.9 0,33.9 0,38.9 33.9,38.9 33.9,72.8 38.9,72.8 38.9,38.9 72.8,38.9"/>
      </g>
    </svg>
}


export function SVGCross(colour: string, style = {}) {
  return <svg viewBox="0 0 55 55" style={style}>
      <g style={{fill: colour}} stroke={colour}>
        <polygon
          stroke={colour}
          points="55,3.5 51.5,0 27.5,24 3.5,0 0,3.5 24,27.5 0,51.5 3.5,55 27.5,31 51.5,55 55,51.5 31,27.5"/>
      </g>
    </svg>
}

export function SVGFullscreen(colour: string, style = {}) {
  return <svg viewBox="0 0 55 55" style={style}>
      <g style={{fill: colour}} stroke={colour}>
        <polygon points="50,25 50,50 25,50 25,55 55,55 55,25"/>
        <polygon points="30,0 0,0 0,30 5,30 5,5 30,5"/>
      </g>
    </svg>
}

export function SVGArrowRight(colour: string, style = {}) {
  return <svg viewBox="0 0 70 70" style={style}>
      <g style={{fill: colour}} stroke={colour}>
        <polygon
          points="34.8,0 31.3,3.5 60.4,32.5 0,32.5 0,37.5 60.4,37.5 31.3,66.5 34.8,70 70,35"/>
      </g>
    </svg>
}


export function SVGArrowLeft(colour: string, style = {}) {
  return <svg viewBox="0 0 70 70" style={style}>
      <g style={{fill: colour}} stroke={colour}>
        <polygon
          points="35.2,70 38.7,66.5 9.6,37.5 70,37.5 70,32.5 9.6,32.5 38.7,3.5 35.2,0 0,35"/>
      </g>
    </svg>
}

export function SVGArrowDown(colour: string, style = {}) {
  return <svg viewBox="0 0 70 70" style={style}>
      <g style={{fill: colour}} stroke={colour}>
        <polygon
          points="70,34.8 66.5,31.3 37.5,60.4 37.5,0 32.5,0 32.5,60.4 3.5,31.3 0,34.8 35,70"/>
      </g>
    </svg>
}

export function SVGHamburger(colour: string, style = {}) {
  return <svg viewBox="0 0 55 70" style={style}>
      <g id="hamburger" style={{fill: colour}} stroke={colour}>
        <rect className="line1" x="0" y="0" width="55" height="5"/>
        <rect className="line2" x="0" y="25" width="55" height="5"/>
        <rect className="line3" x="0" y="50" width="55" height="5"/>
      </g>
    </svg>
}
