import * as React from "react";

import {connect} from "react-redux"

import {Link} from "react-router-dom";
import {IData} from "../../reducers/dataReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {Helmet} from "react-helmet";

import {statusTypes} from "../../actions/actionTypes"
import {pColour, textColour} from '../../tools/colorTools'

import {SimpleContainerPage, QuotePage} from '../pages/ContentPage'
import MasonryList from "../masonry/MasonryList"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {ScrollHolder} from '../pages/Scrolling'

import {ArtistURL} from '../../tools/pageTools'
import {SVGArrowRight} from '../../tools/svgTools'
import {shuffle} from '../../tools/generalTools'
import styles from './artist.module.css';

import {translate} from '../../tools/translateTools'

interface State {
  path: string;
  randomSet: number[];
}

interface parentProps {
  match: any;
  location: any;
}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf,
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Artist extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      path: '',
      randomSet: []
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if(props.location.pathname != state.path){
      if (props.data.status > statusTypes.LOADING) {
        const artist = props.data.artists.byIds[props.match.params.id]

        if (artist === undefined || artist.published == false)
          return

        const url = ArtistURL(artist)
        props.resetCrumbs()

        let home: ICrumb = {title: 'home', link: "/home", bg: "#f1f1f2"}
        let explore: ICrumb = {title: 'explore', link: "/explore", bg: "#f1f1f2"}
        let artistname: ICrumb = {title: {en: artist.name, nl: artist.name}, link: url, bg: artist.color}
        props.addCrumbs([home, explore, artistname])

        let result = new Array();
        Object.keys(props.data.artists.byIds).map((id) => {
          if (id != props.match.params.id)
            if (props.data.artists.byIds[id].published)
              result.push(id)
        })

        return {
          path: props.location.pathname,
          randomSet: shuffle(result),
        }
      }
    }

    return null;
  }

  render() {
    if (!this.haveData())
      return <div> loading </div>

    const artist_ids = this.props.data.artists.byIds
    const artist = artist_ids[this.props.match.params.id]

    if (artist === undefined || artist.published == false)
      return this.not_found()

    const first = this.props.data.artworks.byIds[artist.artworks[0]]

    const quote = (this.props.anaf.lang == "EN")? artist.quote_en : artist.quote_nl
    const quote_foot = (this.props.anaf.lang == "EN")? artist.quote_footer_en : artist.quote_footer_nl

    const show_quote = quote? quote.length > 0 : false



    return <div>
      <Helmet>
        <title>AkzoNobel Art Foundation | {artist.name} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | ${artist.name}`} />
      </Helmet>

      {this.introPage(artist, show_quote)}
      {(show_quote)? this.quotePage(artist, quote, quote_foot) : ''}
      {this.bioPage(artist, show_quote)}
      <ScrollHolder id="content">
        <div className={styles.masonpadding}></div>
        <MasonryList artwork_ids={artist.artworks}/>
      </ScrollHolder>
      {this.ymal()}
    </div>
  }

  not_found(){
    return <SimpleContainerPage pageId="intro" bgColour="rgb(100, 100, 250)">
      <div className={`${styles.bioHolder} row mr-0 ml-0 align-items-center`}>
        <h1 className={styles.title}>{translate(this.props.anaf.lang, 'not_found')}</h1>
      </div>
      </SimpleContainerPage>
  }

  introPage(artist, show_quote) {
    return <SimpleContainerPage pageId="intro" next={show_quote? 'quote' : 'bio'} bgColour={artist.color}>
      <div className={`${styles.bioHolder} row mr-0 ml-0 align-items-center`}>
        <h1 className={styles.title}>{artist.name}</h1>
      </div>
      </SimpleContainerPage>
  }

  quotePage(artist, quote, quote_foot) {
    return <SimpleContainerPage pageId="quote" next="bio" prev="intro">
        <div className={`${styles.contentHolder} ${styles.quotePageContentHolder} d-flex align-items-center`}>
          <div className={styles.quotePageContent}>
            <p className={`${styles.quote} bold`} style={{color: artist.color}}>&ldquo;{quote}&rdquo;</p>
            <p className={`${styles.quotefoot}`} style={{color: artist.color}}>{quote_foot}</p>
          </div>
        </div>
      </SimpleContainerPage>
  }

  bioPage(artist, show_quote) {
    const biotext = (this.props.anaf.lang == "EN")? artist.bio_en : artist.bio_nl
    const bio = <div dangerouslySetInnerHTML={{ __html: biotext }}></div>

    return <SimpleContainerPage pageId="bio" next="content" prev={show_quote? 'quote' : 'intro'}>
      <div className={`${styles.bioHolder} row mr-0 ml-0 align-items-center`}>
        <div className="row mr-0 ml-0">
          <div style={{color: artist.color}} className={`${styles.bio} offset-md-2 col-12 col-md-7 content d-flex bold`}>
            {bio}
          </div>
         {this.background(artist)}
        </div>
      </div>
    </SimpleContainerPage>
  }

  background(artist) {
    const bgtext = (this.props.anaf.lang == "EN")? artist.bio_short_en : artist.bio_short_nl
    const bg = <div dangerouslySetInnerHTML={{ __html: bgtext }}></div>

    const valid_bg = bgtext? bgtext.length > 0 : false

    if (!valid_bg) {
      return <div className={`${styles.background} col-12 col-md-3`}>
      {this.websiteLink(artist.website)}
      </div>
    }

    return <div className={`${styles.background} col-12 col-md-3`}>
        <div className={`bold`}>{translate(this.props.anaf.lang, 'artist_background')}</div>
        <div className={`${styles.bgtext}`}>{bg}</div>
        {this.websiteLink(artist.website)}
      </div>
  }

  websiteLink(link) {

    const valid_link = link? link.length > 0 : false

    if (!valid_link)
      return <span></span>

    var url = link
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = "http://" + url;
    }

    const arrowStyle = {
      strokeWidth: 3,
      width: 12,
      float: 'right',
      paddingTop: 5,
    }

    return <div>
    <div className={`bold`}>{translate(this.props.anaf.lang, 'artist_website')}</div>
    <div>
      <a href={url} target="_blank">{link}</a>
      {SVGArrowRight('#333', arrowStyle)}
    </div>
    </div>
 }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

  ymal(){
    const suggestions = this.state.randomSet.slice(0,3);

    return <div className={`${styles.ymalHolder} bold`}>
      <h2>{translate(this.props.anaf.lang, 'you_might_also_like')}</h2>
      <div className={styles.tagHolder}>
        {suggestions.map((id) => {
          let artist = this.props.data.artists.byIds[id]

          return <Link key={artist.id} className="bold link" to={ArtistURL(artist)}>
            <div className={styles.tag}>
            {artist.name} <div>{SVGArrowRight('#333', {strokeWidth: 3})}</div>
            </div>
          </Link>
        }
        )}
      </div>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
