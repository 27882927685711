import { applyMiddleware, createStore, Store} from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import reducer from "./reducers"
import { actionTypes } from "./actions/actionTypes"


const actionTypeEnumToString = (action: any): any => typeof action.type === 'number' && actionTypes[action.type] ? ({
  type: actionTypes[action.type],
  payload: action.payload,
}) : action;

// const logger = createLogger({ actionTransformer: actionTypeEnumToString });

export interface AppState {
}

// const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);
export const store: Store<AppState> = createStore(reducer, middleware);
