import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import {Helmet} from "react-helmet";
import classNames from "classnames"

import {statusTypes} from "../../actions/actionTypes"

import {techniqueList} from '../../tools/tagTools'


import {IData} from "../../reducers/dataReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {translate} from '../../tools/translateTools'
import {Button} from "../buttons/Button"
import {stripMarks} from '../pages/ContentPage'

import {SVGPlus} from "../../tools/svgTools"
import {scrollToID} from "../../tools/pageTools"

import styles from './styles.module.scss'

interface State {
  selected: number;
  path: string;
}

interface parentProps {
  match: any;
  location: any;
}

interface StoreProps {
  data: IData;
  dataStatus: statusTypes;
  lang: string
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
    lang: state.anaf.lang,
  }
}

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Tour extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      selected: -1,
      path: '',
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return null;
  }

  render() {
    if(!this.haveData()) {
      return <div>Loading...</div>
    }
    return this.pagecontainer();
  }


  pagecontainer() {
    const exhibition = this.props.data.exhibitions.byIds[this.props.match.params.id]
    let title = stripMarks((this.props.lang == "EN")? exhibition.title_en : exhibition.title_nl)

    const collection = exhibition.artworks.map((id) => this.props.data.artworks.byIds[id])
    const sorted = this.sort_artists(collection)

    return <div className={classNames(styles.page, "content")}>
      <Helmet>
        <title>AkzoNobel Art Foundation | Tour: {title} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | Tour`} />
      </Helmet>

      <h1 className={classNames("secundair", styles.mainTitle)}>{title}</h1>
      <div className={styles.border}></div>

      {sorted.map(
        (artwork) => this.tourItem(artwork, exhibition.annotate[artwork.id])
      )}

      {this.homebutton()}

    </div>
  }

  tourItem(artwork, annotates) {
    if (!artwork)
      return <span></span>

    if (artwork.on_location != 1)
      return <span></span>

    const artist = this.props.data.artists.byIds[artwork.artist_id]
    const text = (this.props.lang == "EN")? annotates.en : annotates.nl
    const show = this.state.selected == artwork.id;
    const contentStyle = { display: show? 'flex' : 'none' }

    return <div key={artwork.id} id={`page_${artwork.id}`}>
      <div className="row link" onClick={() => this.toggleItem(artwork.id)}>

        <div className={classNames("col-10 col-sm-3")}>
          <div className={classNames("bold")}>
            <span className="d-block d-sm-none">{artist.name}</span>
            <span className="d-none d-sm-block">{artwork.title}</span>
          </div>
          <div className={classNames("d-block d-sm-none")}>{artwork.title}</div>
        </div>
        <div className={classNames('col-2', 'col-sm-1', 'text-right', styles.crossholder, {[`${styles.rotate}`]: show} )}>
          {SVGPlus('#333')}
        </div>
        <div className={classNames(styles.title, "bold d-none d-sm-flex col-sm-8")}>
          {artist.name}
        </div>

      </div>

      <div style={contentStyle} className="row">
        <div className={classNames(styles.imageHolder, "col-9 col-sm-4")}>
          {this.images(artwork)}
          {this.specs(artwork)}

        </div>
        <div className="col col-sm-8 pl-0">
          <div className={classNames(styles.content)} dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>

      <div className={styles.border}></div>

    </div>
  }

  sort_artists(artworks) {
    return Object.keys(artworks).map((id) => artworks[id]).sort((a, b) => {
      let aa = this.props.data.artists.byIds[a.artist_id]
      let bb = this.props.data.artists.byIds[b.artist_id]
    if(aa.surname < bb.surname)
      return -1
    if (aa.surname > bb.surname)
      return 1
    return 0
    })
  }

  images(artwork) {
    if(artwork.part_of_set)
      return <div>
      {artwork.subset.slice(0,3).map(id => 
        <div className={styles.reel}>
          <img className="img-fluid" key={id} src={this.props.data.artworks.byIds[id].image}/>
        </div>
      )}
    </div>
    return <div><img className="img-fluid" src={artwork.image} /></div>
  }

  specs(artwork) {
    let techniques = techniqueList();
    let technique = techniques[artwork.technique_id]
    let tname = (this.props.lang == "EN")? technique.en : technique.nl
  
    return <div className={styles.specs}>
      {artwork.year} <br />
      {tname} <br/>
      {this.dimen(artwork)}
    </div>
  }
  
  dimen(artwork) {
    if (artwork.technique_id == 7 || artwork.dim_width == 0)
      return ''
    return `${this.round(artwork.dim_width)} x ${this.round(artwork.dim_height)} CM`
  }

  round(dim) {
    if ((dim * 10) % 10 == 0)
      return Math.round(dim)
    return dim
  }

  toggleItem(id){

    if (this.state.selected != id)
      this.scroll_to_item(id)

    this.setState({
      selected: (this.state.selected == id)? 0 : id,
    })
  }

  scroll_to_item(id){
    let header = document.getElementById('tourheader')
    let elemRect = header.getBoundingClientRect()
    // wait for the 'closing' of the previous item
    setTimeout(
      () => scrollToID(id, -elemRect.height - 2), 100)
  }

  homebutton() {
    return <div className={styles.bottom_buttons}>
      <Link to="/home">
        <Button
          arrow="left"
          color="#333"
          front={true}
          extraclass={styles.backbutton}
          text={translate(this.props.lang, 'leave_tour')}
        />
        </Link>
    </div>
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tour);
