import * as React from "react";
import * as moment from 'moment'

import {connect} from "react-redux"
import {Link} from "react-router-dom";

import {IEevents, IEevent, IEventId} from "../../reducers/eventReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"

import {loadEvents} from "../../actions/eventActions"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {statusTypes} from "../../actions/actionTypes"
import {translate} from '../../tools/translateTools'

import std from '../../images/savethedate2.svg'
import {Button} from "../buttons/Button"

import styles from './styles.module.css'

interface State {}

interface parentProps {}

interface StoreProps {
  events: IEevents;
  anaf: IAnaf;
}

interface DispatchProps {
  loadEvents: Function;
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    events: state.events,
    anaf: state.anaf,
  }
};

const mapDispatchToProps = dispatch => ({
  loadEvents: () => { dispatch( loadEvents())},
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Savetheday extends React.Component<Props, State> {


  componentDidMount() {
    this.props.loadEvents();
    this.props.resetCrumbs()

    let home: ICrumb = {title: 'home', link: "/home", bg: "#ff5032"}
    let std: ICrumb = {title: 'save_the_day', link: "/savethedate", bg: "#ff5032"}
    this.props.addCrumbs([home, std])
  }

  render() {
    let w = this.props.anaf.width / 2.5
    let h = this.props.anaf.width / 5

    const leftStyle = {
      // height: '100%',
    }

    const rightStyle = {
      borderStyle: 'solid',
      borderWidth: '0px 0px 0px 3px',
      marginBottom: '100px',
      padding: '0px 20px',
    }

    const stdStyle = {
      margin: '30px auto',
      top: h,
      width: w,
      height: w,
      backgroundColor: 'white',
      backgroundImage: `url(${std})`,
      backgroundSize: 'cover',
      borderRadius: `${w/2}px ${w/4}px ${w/2}px ${w/2}px`,
    }


    const ArrowStyle = {
     height: 40,
    }

    return <div className={`fade show ${styles.stdwindow}`}>
      <div className="row no-gutters" style={{height: '100%'}}>
        <div className="col" style={leftStyle}>
          <div style={stdStyle}></div>
        </div>
        <div className={`col ${styles.rightside}`}>
          {(this.props.events.status == statusTypes.LOADED)? this.eventContent() : <div>Loading...</div> }
          <div className={styles.eventButton}>
          <Link to="/events">
            <Button arrow="right" color="#ff5032" text="Events"/>
          </Link>
          </div>
          {(this.props.events.status == statusTypes.LOADED)? this.calButton() : <div></div> }
        </div>
      </div>
    </div>
  }

  eventContent() {
    const event = this.events()[0]

    const text = (this.props.anaf.lang == "EN")? event.description_en : event.description_nl

    return <div>
    <h2 className={styles.event}>{event.moment}</h2>
    <h2 className={styles.event}>{event.title}</h2>
    <div className={styles.eventconten} dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  }

  calButton() {
    const event = this.events()[0]

    return <a href={this.cal(event)}>
      <Button arrow="plus" color="#ff5032" front={true} text={translate(this.props.anaf.lang, 'save_to_my_agenda')}/>
    </a>
  }

  cal(event) {
    const text = (this.props.anaf.lang == "EN")? event.description_en : event.description_nl
    const stripHtml = /(<([^>]+)>)/ig;
    const rmEnters = /(\r\n|\n|\r)/gm;

    // temp workaround for backend data
    const m = event.moment
    let date = m.substring(6, 10) + '-'+ m.substring(3, 5) + '-' + m.substring(0, 2)

    let calendarUrl = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      // "URL:" + document.URL,
      "DTSTART:" + this.formatTime(date),
      // "DTEND:" + this.formatTime(event.endTime),
      "SUMMARY:" + event.title,
      "DESCRIPTION:" + text.replace(stripHtml, ''),
      "LOCATION:" + event.address.replace(rmEnters, ' '),
      "END:VEVENT",
      "END:VCALENDAR"
    ].join("\n");

    calendarUrl = encodeURI(
      "data:text/calendar;charset=utf8," + calendarUrl
      );

    return calendarUrl
  }

  formatTime(date) {
    let formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
    return formattedDate.replace("+00:00", "Z");
  }

  events() {
   const events: IEventId = this.props.events.eventlist.byIds

    let unsorted = new Array()
    unsorted = Object.keys(events).map((id) => events[id])
    unsorted.sort((a,b) => {
      let am = a.moment.substring(6, 10) + a.moment.substring(3, 5) + a.moment.substring(0, 2)
      let bm = b.moment.substring(6, 10) + b.moment.substring(3, 5) + b.moment.substring(0, 2)

      if (am < bm)
        return 1
      if (am > bm)
        return -1;
      return 0;
    })

    return unsorted
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Savetheday);
