import * as React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import {IAnaf} from "../../reducers/anafReducer"
import {Button} from "../buttons/Button"
import {submitContact} from '../../actions/anafActions'
import {translate} from '../../tools/translateTools'
import styles from './styles.module.css'

enum FORMSTATE {
  Init,
  Failed,
  Success,
}

interface State {
  name: string;
  email: string;
  msg: string;
  formstate: FORMSTATE;
}

interface parentProps {
  history: any;
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {
  submitContact: Function;
}

const mapStateToProps = state => {
  return {
    anaf: state.anaf
  }
}

const mapDispatchToProps = dispatch => ({
  submitContact: (name: string, email: string, msg: string) => {dispatch(submitContact(name, email, msg))}
});

type Props = StoreProps & DispatchProps & parentProps


export class Contactform extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      msg: '',
      formstate: FORMSTATE.Init,
    }
  }

  render() {


    return <div className={styles.subwindow}>
      <div className="bold">
        <h1 className={styles.header}>Contact</h1>
        <h2 className={styles.subheader}><a href="email:artfoundation@akzonobel.com">artfoundation@akzonobel.com</a></h2>
      </div>
        {this.props.anaf.contactSubmit? this.msgSent() : this.form()}
    </div>
  }

  form() {
    return  <div className={styles.formwindow}>
        <div className="row">
          <div className="col">
            <input
              type="text"
              placeholder={translate(this.props.anaf.lang, "name")}
              value={this.state.name}
              onChange={(e) => this.updateName(e.target.value)}
              className={styles.input}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="text"
              placeholder="Email"
              value={this.state.email}
              onChange={(e) => this.updateEmail(e.target.value)}
              className={styles.input}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={styles.question}>{translate(this.props.anaf.lang, "question")}</div>
            <textarea
              value={this.state.msg}
              onChange={(e) => this.updateMsg(e.target.value)}
              className={styles.textarea}
              />
          </div>
        </div>

        <div onClick={() => this.submitQuestion()}>
          <Button style={{float: 'right'}} arrow="right" text={translate(this.props.anaf.lang, 'send')} color="#b4d2e6" />
        </div>

        {this.showerrors()}
      </div>
  }

  showerrors() {
    if (this.state.formstate != FORMSTATE.Failed)
      return <div></div>
    return <div className={styles.error}>
      {translate(this.props.anaf.lang, 'enter_all_fields')}
    </div>
  }

  msgSent() {
    return <div className={`bold ${styles.subheader}`}>
      <br /><br />
      {translate(this.props.anaf.lang, 'msg_sent')}
    </div>
  }

  updateName(val) {
    this.setState({
      ...this.state,
      name: val,
    })
  }

  updateEmail(val) {
    this.setState({
      ...this.state,
      email: val,
    })
  }

  updateMsg(val) {
    this.setState({
      ...this.state,
      msg: val,
    })
  }

  submitQuestion() {
    const s = this.state
    if (s.name.length > 0 &&
      s.email.length > 0 &&
      s.msg.length > 0) {
      this.props.submitContact(s.name, s.email, s.msg)
    }
    else {
      this.setState({
        ...this.state,
        formstate: FORMSTATE.Failed,
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contactform);
