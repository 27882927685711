import * as React from "react";
import {SVGArrowRight, SVGPlus, SVGArrowDown} from '../../tools/svgTools'

import styles from './tag.module.css'

interface State {
}

interface parentProps {
  arrow: string;
  text: string;
  key: string;
  style?: any;
  onClick: Function;
}

export class ResultTag extends React.Component<parentProps, State> {


  render() {

  const iconStyle = {
    strokeWidth: 2,
    height: 20,
    margin: '0px 5px 5px 7px',
  }

    return <div onClick={() => this.clicked()} style={this.props.style} className={`link ${styles.button}`}>
      <div className="bold">{this.props.text} {SVGArrowRight("#fff", iconStyle)}</div>
    </div>
  }

  clicked() {
    this.props.onClick();
  }
}
