import * as React from "react";

import {connect} from "react-redux"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import * as CONST from "../exhibition/consts"

import classNames from 'classnames'

import {IData, IExhibitionId} from "../../reducers/dataReducer"
import {IAnaf, ICrumb} from "../../reducers/anafReducer"
import {statusTypes} from "../../actions/actionTypes"
import {resetCrumbs, addCrumbs} from "../../actions/anafActions"
import {translate} from '../../tools/translateTools'
import {SimpleContainerPage} from '../pages/ContentPage'

import {colorlist} from '../../tools/colorTools'

import arrow_rb from '../../images/AAF_SVG_incons_AAF Arrow 135.svg'
import arrow_lb from '../../images/AAF_SVG_incons_AAF Arrow -135.svg'
import arrow_rt from '../../images/AAF_SVG_incons_AAF Arrow 45.svg'
import arrow_lt from '../../images/AAF_SVG_incons_AAF Arrow -45.svg'

import Masonry from 'react-masonry-component';

import './global_masonry.css'
import styles from './styles.module.css'

interface State {}

interface parentProps {
}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
    anaf: state.anaf,
  }
};

const mapDispatchToProps = dispatch => ({
  resetCrumbs: () => { dispatch( resetCrumbs())},
  addCrumbs: (crumb: ICrumb[]) => { dispatch( addCrumbs(crumb))},
});

type Props = StoreProps & DispatchProps & parentProps

const masonryOptions = {
  transitionDuration: 800,
  columnWidth: '.hgrid-sizer',
  gutter: '.hgutter-sizer',
  itemSelector: '.hgrid-item',
  percentPosition: true
};

const MAXHEIGHT = 50;

export class Highlights extends React.Component<Props, State> {
  masonry: any;
  layouts: any;

  componentDidMount() {
    this.props.resetCrumbs()
    let home: ICrumb = {title: 'home', link: "/home", bg: "#f1f1f2"}
    let explore: ICrumb = {title: 'explore', link: "/explore/intro", bg: "#f1f1f2"}
    let highlights: ICrumb = {title: 'highlights', link: "/highlights", bg: "#6464fa"}

    this.props.addCrumbs([home, explore, highlights])
  }

  constructor(props) {
    super(props)

    this.state = {}

    this.layouts = this.layoutlist()
  }

  render() {
    if(!this.haveData()) {
      return <div>Loading...</div>
    }

    const tit = translate(this.props.anaf.lang, 'highlights')
    return <div>

      <Helmet>
        <title>AkzoNobel Art Foundation | {tit} </title>
        <meta name="description" content={`AkzoNobel Art Foundation | ${tit}`} />
      </Helmet>

      {this.introPage()}
      <SimpleContainerPage pageId="p1">
        <div className={`content ${styles.masonry}`}>
          <Masonry
            ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
            className={'my-gallery-class'}
            options={masonryOptions}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}>
            {
              [ <div className="hgrid-sizer" key="sizer"></div>,
                <div className="hgutter-sizer" key="gsizer"></div>,
                ...(this.blocks())
              ]
            }
          </Masonry>
        </div>
      </SimpleContainerPage>
    </div>
  }

  introPage() {
    return <SimpleContainerPage pageId="p0" next="p1" bgColour="#6464fa">
      <div className={classNames(styles.contentHolder, 'd-flex', 'align-items-center', 'bold')}>
        <div style={{width: '100%'}}>
          <h1 className={classNames("secundair", styles.mainTitle)}>{translate(this.props.anaf.lang, 'highlights')}</h1>
          <div className={styles.intro}>
            {(this.props.anaf.lang == "EN")?
              "In the ‘Highlights’ section, we invite art lovers and art professionals to answer art-related questions and create their own online exhibition with their favorite artworks from the AkzoNobel Art Collection." :
              "In de nieuwe ‘Highlights’ rubriek nodigen wij kunstliefhebbers en kunstprofessionals uit om kunst gerelateerde vragen te beantwoorden en een online tentoonstelling te maken met hun persoonlijke favorieten werken uit de AkzoNobel kunstcollectie."
            }
          </div>
        </div>
      </div>
    </SimpleContainerPage>
  }

  blocks() {
    return this.sorted().map((item, index) => this.block(item, index))
  }

  block(item, index) {
    const c = colorlist()[item.colour]
    let list = this.layoutlist()
    let layout = list[index % list.length]
    const subCont = {
      width: '100%',
      display: 'flex',
      backgroundColor: `rgb(${c.r},${c.g},${c.b})`,
    }

    return <div key={item.id} className={`hgrid-item hg${layout.w}`}>
      {(layout.align == 'bot') ? <div className={styles.padding}></div> : ''}
      <Link to={"exhibition/" + item.id}><div style={subCont} className={`d-flex flex-column ${layout.r} height${layout.h}`}>
        {this.topArrow(layout.r)}
        <div className={styles.text}>
          <div className={styles.func}>{(this.props.anaf.lang == "EN")? item.hfunction_en : item.hfunction_nl}</div>
          <div className={styles.func}>{item.hlocation}</div>
          <div className={`bold ${styles.name}`}>{item.hname}</div>
        </div>
        {this.botArrow(layout.r)}
      </div></Link>
    </div>
  }

  topArrow(pos) {
    switch(pos){
      case 'rlt':
        return <div className="mb-auto" style={{textAlign: 'right'}}><img src={arrow_rt} className={styles.arrow} /></div>
      case 'rrt':
        return <div className="mb-auto" style={{textAlign: 'left'}}><img src={arrow_lt} className={styles.arrow} /></div>
    }
  }

  botArrow(pos) {
    switch(pos){
      case 'rlb':
        return <div className="mt-auto"><img src={arrow_lb} className={styles.arrow} /></div>
      case 'rrb':
        return <div className="mt-auto" style={{textAlign: 'right'}}><img src={arrow_rb} className={styles.arrow} /></div>
    }
  }

  layoutlist() {
    let list = new Array()
    list.push(this.l('#e6c800', 1, 2, 'bot', 'rlt'))
    list.push(this.l('#b5e1dd', 2, 2, 'top', 'rlb'))
    list.push(this.l('#c89696', 1, 2, 'top', 'rlb'))
    list.push(this.l('#6464fa', 2, 2, 'top', 'rrb'))
    list.push(this.l('#ff5032', 2, 2, 'top', 'rlb'))
    list.push(this.l('#96bed2', 2, 2, 'top', 'rrt'))
    list.push(this.l('#aa3c64', 2, 2, 'top', 'rrt'))

    return list
  }

  l(color, height, width, align, rounding) {
    return {color: color, h: height, w: width, align: align, r: rounding}
  }

  // sort based on opening date
  sorted() {
    let unsorted = this.filtered()
    unsorted.sort((a,b) => {
      let am = a.opened_at.substring(0, 4) + a.opened_at.substring(5, 7) + a.opened_at.substring(8, 10)
      let bm = b.opened_at.substring(0, 4) + b.opened_at.substring(5, 7) + b.opened_at.substring(8, 10)

      if (am < bm)
        return 1
      if (am > bm)
        return -1;
      return 0;
    })

    return unsorted
  }

  // only show highlights
  filtered() {
    const items: IExhibitionId = this.props.data.exhibitions.byIds
    let filtered = new Array()
    Object.keys(items).map((id) => {
      if (items[id].etype == CONST.HIGHLIGHT)
        filtered.push(items[id])
    })
    return filtered
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Highlights);
